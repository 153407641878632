import React, {ReactNode} from 'react';
import {DateTime} from 'luxon';
import {xAxisWithTicksStyle, yAxisStyle} from '../../services/graphService';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryArea,
  VictoryGroup,
} from 'victory';
import {Box, useTheme} from '@mui/material';
import {SizeMe} from 'react-sizeme';
import {AvgDataObject} from '../../interfaces';

interface Props {
  data: AvgDataObject[][];
  sessionTimes: {startTime: string; endTime: string}[];
  metric: string;
  xAxisOverride?: ReactNode;
  yAxisOverride?: ReactNode;
}

const getXAxisTickValues = (maxTime: number) => {
  const values = [];

  if (maxTime > 60 * 60) {
    for (let i = 0; i < Math.ceil(maxTime / (60 * 20)); i++) {
      values.push(60 * 20 * i);
    }
  } else if (maxTime > 60 * 15) {
    for (let i = 0; i < Math.ceil(maxTime / (60 * 5)); i++) {
      values.push(60 * 5 * i);
    }
  } else {
    for (let i = 0; i < Math.ceil(maxTime / 60); i++) {
      values.push(60 * i);
    }
  }

  return values;
};

const TeamSessionGroupLineChart: React.FC<Props> = props => {
  const theme = useTheme();

  if (!props.data?.length) {
    return <></>;
  }

  const orderedSessionTimes = props.sessionTimes.sort(
    (a, b) => new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf(),
  );

  const recalculatedData = props.data.map((avg, index) => {
    if (index === 0) {
      // If first period just return data
      return avg;
    }

    const currentPeriodStartTime = DateTime.fromISO(
      orderedSessionTimes[index].startTime,
    );

    const previousPeriodStartTime = DateTime.fromISO(
      orderedSessionTimes[0].startTime,
    );

    // Current period start time minus the first period start time
    const offSetValue =
      currentPeriodStartTime.toSeconds() - previousPeriodStartTime.toSeconds();

    const addPreviousTimes = avg.map(a => {
      const newData = {...a};
      newData.time = (a.time || 0) + offSetValue;
      return newData;
    });
    return addPreviousTimes;
  });

  const maxTime =
    DateTime.fromISO(
      orderedSessionTimes[orderedSessionTimes.length - 1].endTime,
    ).toSeconds() -
    DateTime.fromISO(orderedSessionTimes[0].startTime).toSeconds();

  const maxValue = Math.max(
    ...recalculatedData.flatMap(rd => rd.map(r => r.value)),
  );

  return (
    <Box>
      <SizeMe>
        {({size}) => (
          <VictoryChart
            width={size.width ?? 1100}
            domainPadding={{x: 0, y: 30}}
            padding={{left: 0, top: 30, bottom: 30, right: 0}}
            containerComponent={
              <VictoryVoronoiContainer
                voronoiDimension="x"
                labels={({datum}) => `${datum.y.toFixed(1)}${props.metric}`}
                labelComponent={
                  <VictoryTooltip
                    style={{
                      fontSize: 20,
                      fontFamily: theme.typography.fontFamily,
                    }}
                    cornerRadius={5}
                    pointerLength={20}
                    flyoutStyle={{stroke: 'white', fill: 'white'}}
                  />
                }
                voronoiBlacklist={['margin', 'linedata']}
              />
            }>
            {recalculatedData.map((dataset, index) => (
              <VictoryGroup
                key={`sessionGroupChart-${orderedSessionTimes[index].startTime}`}>
                {index !== 0 && (
                  <VictoryLine
                    name="margin"
                    style={{
                      data: {
                        stroke: 'gray',
                        strokeWidth: 2,
                      },
                    }}
                    data={[
                      {x: dataset[0]?.time, y: 0},
                      {
                        x: dataset[0]?.time,
                        y: maxValue,
                      },
                    ]}
                  />
                )}
                <VictoryArea
                  name={`area-${index}`}
                  style={{data: {fill: 'rgba(242,96,20,0.15)'}}}
                  data={dataset.map(point => ({
                    x: point.time,
                    y: point.value,
                  }))}
                />
                <VictoryLine
                  name="linedata"
                  data={dataset.map(point => ({
                    x: point.time,
                    y: point.value,
                  }))}
                  style={{
                    data: {stroke: theme.palette.primary.main, strokeWidth: 1},
                    labels: {fill: 'white', fontSize: 20},
                  }}
                  interpolation="monotoneX"
                />
                {index < props.data.length - 1 && (
                  <VictoryLine
                    style={{
                      data: {
                        stroke: 'gray',
                        strokeWidth: 2,
                      },
                    }}
                    name="margin"
                    data={[
                      {x: dataset[dataset.length - 1]?.time, y: 0},
                      {
                        x: dataset[dataset.length - 1]?.time,
                        y: maxValue,
                      },
                    ]}
                  />
                )}
              </VictoryGroup>
            ))}
            {props.xAxisOverride ? (
              props.xAxisOverride
            ) : (
              <VictoryAxis
                tickFormat={tick => (tick > 0 ? `${tick / 60}m` : '')}
                tickValues={getXAxisTickValues(maxTime)}
                orientation="bottom"
                style={xAxisWithTicksStyle}
              />
            )}
            {props.yAxisOverride ? (
              props.yAxisOverride
            ) : (
              <VictoryAxis
                dependentAxis
                tickFormat={tick => `${tick}${props.metric}`}
                orientation="right"
                style={yAxisStyle}
                offsetX={size.width ?? 0}
              />
            )}
          </VictoryChart>
        )}
      </SizeMe>
    </Box>
  );
};

export default TeamSessionGroupLineChart;
