import {Box, Typography} from '@mui/material';
import React from 'react';

interface Props {
  number?: number;
  expandIfEmpty?: boolean;
}

const PlayerNumber: React.FC<Props> = ({number, expandIfEmpty}) => (
  <Box
    sx={{
      textAlign: expandIfEmpty ? 'left' : null,
      mr: expandIfEmpty ? null : 2,
      minWidth: expandIfEmpty ? 80 : null,
    }}>
    {(number ?? 0) > 0 && (
      <Typography
        sx={{
          fontFamily: 'Orbitron',
          fontWeight: 700,
          fontSize: 40,
        }}>
        {number}
      </Typography>
    )}
  </Box>
);

export default PlayerNumber;
