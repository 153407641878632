import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import PageTitle from '../shared/PageTitle';
import {getTeams} from '../../api/adminAPI';
import TeamsTable from './TeamsTable';
import {Box} from '@mui/material';
import {Team} from '../../interfaces';

interface Props {}

const AdminTeams: React.FC<Props> = () => {
  const {t} = useTranslation();

  const [teams, setTeams] = React.useState<Team[]>([]);

  useEffect(() => {
    // fetch teams
    const fetchData = async () => {
      const teams = await getTeams();
      setTeams(teams);
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('pages.teams')}</title>
      </Helmet>
      <Box>
        <PageTitle>{t('pages.teams')}</PageTitle>
        <TeamsTable teams={teams ?? []} />
      </Box>
    </>
  );
};

export default React.memo(AdminTeams);
