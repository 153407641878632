import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  getPlayersTeamSessionGroup,
  getTeamSessionGroupDetail,
} from '../api/teamSessionGroupAPI';
import {TeamSessionGroup} from '../interfaces';

export interface TeamSessionGroupState {
  teamSessionGroup: TeamSessionGroup | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: TeamSessionGroupState = {
  teamSessionGroup: null,
  status: 'idle',
  error: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
const getTeamSessionGroupDetailAsync = createAsyncThunk(
  'teamSessionGroup/getTeamSessionGroupDetail',
  async (id: number, {rejectWithValue}) => {
    try {
      const response: any = await getTeamSessionGroupDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getPlayersTeamSessionGroupAsync = createAsyncThunk(
  'teamSessionGroup/getPlayersTeamSessionGroupAsync',
  async (data: {id: number; profileId: number}, {rejectWithValue}) => {
    try {
      const response: any = await getPlayersTeamSessionGroup(
        data.id,
        data.profileId,
      );
      return response.data?.data?.teamSessionGroup;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const teamSessionGroupSlice = createSlice({
  name: 'teamSessionGroup',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(getTeamSessionGroupDetailAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(getTeamSessionGroupDetailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.teamSessionGroup = action.payload;
        state.error = null;
      })
      .addCase(getTeamSessionGroupDetailAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.teamSessionGroup = null;
        state.error = action.payload;
      })
      .addCase(getPlayersTeamSessionGroupAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(getPlayersTeamSessionGroupAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.teamSessionGroup = action.payload;
        state.error = null;
      })
      .addCase(getPlayersTeamSessionGroupAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.teamSessionGroup = null;
        state.error = action.payload;
      });
  },
});

export default teamSessionGroupSlice.reducer;

export {getTeamSessionGroupDetailAsync, getPlayersTeamSessionGroupAsync};
