import React, {useCallback} from 'react';
import {Organization} from '../../interfaces';
import {Box, Button, Modal, Typography} from '@mui/material';
import {useForm} from 'react-hook-form';
import styles from '../../styles';
import FormTextField from '../shared/FormTextField';
import {updateOrganization} from '../../api/adminAPI';

type OrganizationUpdateForm = {
  id: number;
  name: string;
  country: string;
  stateOrProvince: string;
};

interface Props {
  organization: Organization;
  loading: boolean;
  onUpdate: () => void;
}

const UpdateOrgModal: React.FC<Props> = ({organization, loading, onUpdate}) => {
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const {handleSubmit, reset, control} = useForm<OrganizationUpdateForm>({
    defaultValues: organization,
  });

  const handleUpdateDialogClick = useCallback(() => {
    setShowUpdateDialog(true);
  }, []);

  const handleAddDialogClose = useCallback(() => {
    reset();
    setPending(false);
    setShowUpdateDialog(false);
  }, [reset]);

  const handleUpdateSubmit = useCallback(
    async (data: OrganizationUpdateForm) => {
      setPending(true);
      await updateOrganization({
        id: data.id,
        name: data.name,
        country: data.country,
        stateOrProvince: data.stateOrProvince,
      });
      onUpdate();
      handleAddDialogClose();
    },
    [handleAddDialogClose, onUpdate],
  );

  return (
    <>
      <Button
        sx={{mb: 2, mr: 2}}
        onClick={handleUpdateDialogClick}
        disabled={loading}>
        Update Organization
      </Button>
      <Modal
        open={showUpdateDialog}
        onClose={handleAddDialogClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={styles.modal}>
          <Typography variant="h4" sx={{mb: 2}}>
            Update Organization
          </Typography>
          <form onSubmit={handleSubmit(handleUpdateSubmit)} className="form">
            <FormTextField
              control={control}
              name="name"
              label="Name"
              required
            />
            <FormTextField
              control={control}
              name="country"
              label="Country"
              required
            />
            <FormTextField
              control={control}
              name="stateOrProvince"
              label="State or Province"
              required
            />
            <Button disabled={pending} type="submit">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default React.memo(UpdateOrgModal);
