import {AxiosPromise} from 'axios';
import api from './api';
import apiGraphQL from './apiGraphQL';

export function getTeamSessionGroupDetail(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/teamsessiongroups/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getPlayersTeamSessionGroup(
  teamSessionGroupId: number,
  profileId: number,
) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `query ($teamSessionGroupId: Int!, $profileId: Int!) {
            teamSessionGroup(teamSessionGroupId: $teamSessionGroupId, profileId: $profileId) {
              id
              teamSessions {
                id
                teamId
                sessionTags {
                  id
                  name
                  code
                  isPlayerSessionTag
                  isTeamSessionTag
                }
                playerSessions {
                  id
                  startTime
                  endTime
                  strideCountLeft
                  strideCountRight
                  activeTime
                  points
                  strintFilteredStrideRateAvg
                  strintFilteredStrideRateMax
                  speedAvg
                  accelAvg
                  speedMax
                  accelMax
                  hustleScore
                  balance
                  strintTotalTime
                  hrAvg
                  speedAvgData {
                    value
                    time
                  }
                  accelAvgData {
                    value
                    time
                  }
                  strideRateAvgData {
                    value
                    time
                  }
                  balanceAvgData {
                    value
                    time
                  }
                  heartRateData {
                    value
                    time
                  }
                  teamSessionId
                  playerSessionShifts {
                    id
                    start
                    startTime
                    end
                    endTime
                    strideCountLeft
                    strideCountRight
                    strideCountTotal
                    strintStrideCountTotal
                    speedAvg
                    speedMax
                    accelAvg
                    accelMax
                    speedAvgV2
                    speedMaxV2
                    accelAvgV2
                    accelMaxV2
                    strideRateAvg
                    strintFilteredStrideRateAvg
                    strintFilteredStrideRateMax
                    balance
                    balanceNormalized
                    strintTotalTime
                    hrMin
                    hrMax
                    hrAvg
                    hrStart
                    hrEnd
                    timeOnIce
                    deviceTimeOnIce
                    valid
                    validInitially
                    canUpdateValid
                    isFavorite
                    playerSessionVideoClips {
                      id
                      key
                      startTime
                      endTime
                      url
                    }
                    shiftTags {
                      id
                      name
                    }
                  }
                }
              }
            }
          }`,
          variables: {
            teamSessionGroupId,
            profileId,
          },
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
