import {Box, Button, TableCell, TableRow} from '@mui/material';
import React from 'react';
import {NavLink} from 'react-router-dom';

import ProfileIdentity from './ProfileIdentity';
import PlayerACWRGasGauge from '../Player/components/PlayerACWRGasGauge';
import PlayerNumber from './PlayerNumber';

interface Props {
  firstName: string;
  lastName: string;
  position?: string;
  profilePictureUrl?: string;
  playerACWR?: number;
  number?: number;
  teamHasNumbers?: boolean;
  playerDashboardLink?: {
    text: string;
    navigationUrl: string;
  };
  buttons?: {
    text: string;
    navigationUrl?: string;
    onClick?: () => void;
  }[];
}

const PlayerListItem: React.FC<Props> = ({
  firstName,
  lastName,
  position,
  playerACWR,
  profilePictureUrl,
  buttons,
  playerDashboardLink,
  number,
  teamHasNumbers,
}) => {
  return (
    <TableRow>
      <TableCell sx={{pl: 2}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <PlayerNumber number={number} expandIfEmpty={teamHasNumbers} />
          <ProfileIdentity
            firstName={firstName}
            lastName={lastName}
            position={position}
            profilePictureUrl={profilePictureUrl}
          />
        </Box>
      </TableCell>
      <TableCell>
        <PlayerACWRGasGauge acwr={playerACWR || 0} small />
      </TableCell>
      {playerDashboardLink && (
        <TableCell>
          <Button component={NavLink} to={playerDashboardLink.navigationUrl}>
            {playerDashboardLink.text}
          </Button>
        </TableCell>
      )}
      <TableCell align="right" sx={{pr: 2}}>
        {(buttons ?? []).map(button => {
          if (button.navigationUrl) {
            return (
              <Button
                key={button.text}
                component={NavLink}
                to={button.navigationUrl}>
                {button.text}
              </Button>
            );
          } else if (button.onClick) {
            return (
              <Button key={button.text} onClick={button.onClick}>
                {button.text}
              </Button>
            );
          }
          return null;
        })}
      </TableCell>
    </TableRow>
  );
};

export default PlayerListItem;
