import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import familySlice from './familySlice';
import playerSessionSlice from './playerSessionSlice';
import teamSessionSlice from './teamSessionSlice';
import profileSlice from './profileSlice';
import deviceSlice from './deviceSlice';
import teamSlice from './teamSlice';
import teamSessionGroupSlice from './teamSessionGroupSlice';
import adminSlice from './adminSlice';
import baselineSlice from './baselineSlice';

const reducer = combineReducers({
  admin: adminSlice,
  profile: profileSlice,
  device: deviceSlice,
  playerSession: playerSessionSlice,
  teamSession: teamSessionSlice,
  teamSessionGroup: teamSessionGroupSlice,
  family: familySlice,
  team: teamSlice,
  baseline: baselineSlice,
});

export const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
