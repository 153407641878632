import {TFunction} from 'i18next';
import {heliosTheme} from '../theme';
import {getDayName} from './dateService';
import {GRAPH_GREEN, GRAPH_RED, GRAPH_YELLOW} from '../styles';

const toolTipStyle = {
  fontSize: 16,
  fontFamily: heliosTheme.typography.fontFamily,
};

const xAxisStyle = {
  axis: {
    stroke: 'rgba(255,255,255,0.9)',
  },
  tickLabels: {
    display: 'none',
  },
};

const xAxisWithTicksStyle = {
  axis: {
    stroke: 'rgba(255,255,255,0.9)',
  },
  tickLabels: {
    fill: 'rgba(255,255,255,1.0)',
    fontSize: 16,
    fontFamily: heliosTheme.typography.fontFamily,
  },
};

const yAxisStyle = {
  tickLabels: {
    fill: 'rgba(255,255,255,1.0)',
    fontSize: 16,
    fontFamily: heliosTheme.typography.fontFamily,
  },
  axis: {
    stroke: 'rgba(255,255,255,0.2)',
  },
};

const getWeekTickFormatFunction =
  (t: TFunction, shortAbbr: boolean) => (tick: number) => {
    const today = new Date();
    const daysAgo = 6 - tick;
    // keeping this code in case we decide to show longer abbrs in the future
    const abbr = t(
      `general.dayNames.${getDayName(
        today.getDay() - daysAgo < 0
          ? today.getDay() + 7 - daysAgo
          : today.getDay() - daysAgo,
      )}.abbr`,
    );
    // only show the first letter
    if (shortAbbr) {
      return abbr[0];
    }
    return abbr;
  };

const getColor = (value: number) => {
  // value from 0 to 1
  if (value < 0) {
    value = 0;
  } else if (value > 1) {
    value = 1;
  }
  const hue = ((1 - value) * 120).toString(10);
  return ['hsl(', hue, ',50%,50%)'].join('');
};

const getLoadValueColor = (value: number) => {
  // Green if less than 1.5 (38%), Red if greater than 1.8 (45%), Yellow if between.
  // 'Value' is the percentage of the pie chart. ACWR tops out at 4 in pie chart, no limit otherwise\
  if (value < 1.5) {
    return GRAPH_GREEN;
  } else if (value < 3) {
    return GRAPH_YELLOW;
  } else {
    return GRAPH_RED;
  }
};

const getUniqueName = (
  currentPlayerName: {firstName: string; lastName: string; profileId: number},
  allPlayerNames: {firstName: string; lastName: string; profileId: number}[],
) => {
  const duplicateLastNamePlayers = allPlayerNames.filter(
    apn =>
      apn.profileId !== currentPlayerName.profileId &&
      apn.lastName === currentPlayerName.lastName,
  );
  if (!duplicateLastNamePlayers.length) {
    return currentPlayerName.lastName;
  }
  let uniqueName = '';
  for (let i = 1; i <= currentPlayerName.firstName.length; i++) {
    uniqueName = `${currentPlayerName.firstName.slice(0, i)} ${currentPlayerName.lastName}`;

    const matchingPlayers = duplicateLastNamePlayers.filter(
      apn => `${apn.firstName.slice(0, i)} ${apn.lastName}` === uniqueName,
    );

    if (!matchingPlayers.length) {
      return uniqueName;
    }
  }

  // If all letters in first name are used and still not unique, return the full name.
  return `${currentPlayerName.firstName} ${currentPlayerName.lastName}`;
};

export {
  xAxisStyle,
  xAxisWithTicksStyle,
  yAxisStyle,
  getWeekTickFormatFunction,
  getColor,
  toolTipStyle,
  getLoadValueColor,
  getUniqueName,
};
