import React from 'react';
import {Avatar, Box, styled, Typography} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import {useTranslation} from 'react-i18next';

const ProfileIdentityWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const ProfilePictureImage = styled(Avatar)(
  (props: {size?: 'default' | 'small'}) => ({
    height: props.size === 'small' ? '40px' : '60px',
    width: props.size === 'small' ? '40px' : '60px',
    marginRight: '10px',
    background: 'transparent',
    border: 'solid 1px #fff',
  }),
);

interface Props {
  firstName?: string;
  lastName?: string;
  position?: string;
  profilePictureUrl?: string;
  size?: 'default' | 'small';
}

const ProfileIdentity: React.FC<Props> = ({
  firstName,
  lastName,
  position,
  profilePictureUrl,
  size,
}) => {
  const {t} = useTranslation();

  return (
    <ProfileIdentityWrapper>
      {profilePictureUrl ? (
        <ProfilePictureImage
          size={size}
          src={profilePictureUrl}
          alt={`${firstName} ${lastName}`}
        />
      ) : (
        <ProfilePictureImage size={size}>
          <PersonIcon
            color="info"
            sx={{fontSize: size === 'small' ? '24px' : '40px'}}
          />
        </ProfilePictureImage>
      )}
      <Box>
        <Typography variant="h4">
          <em>{`${firstName} ${lastName}`}</em>
        </Typography>
        {position && (
          <Typography variant="body1">{t(`position.${position}`)}</Typography>
        )}
      </Box>
    </ProfileIdentityWrapper>
  );
};

export default ProfileIdentity;
