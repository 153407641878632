import React, {useMemo} from 'react';
import {Box} from '@mui/material';
import {VictoryAnimation, VictoryLabel, VictoryPie} from 'victory';
import {setDecimalPlaces} from '../../../services/mathService';
import {getLoadValueColor} from '../../../services/graphService';
import {ACWR_MAX} from '../../../constants';

interface Props {
  acwr: number;
  small?: boolean;
}

const PlayerACWRGasGauge: React.FC<Props> = ({acwr, small}) => {
  const acwrDisplay = useMemo(() => {
    if (acwr >= ACWR_MAX) {
      return ACWR_MAX;
    }
    return acwr;
  }, [acwr]);

  const getData = (acwr: number) => {
    return [
      {x: 1, y: acwr},
      {x: 2, y: ACWR_MAX - acwr},
    ];
  };

  return (
    <Box>
      <svg
        viewBox="0 0 400 400"
        width={small ? 85 : 150}
        height={small ? 85 : 150}>
        <VictoryPie
          standalone={false}
          animate={{duration: 1000}}
          startAngle={-180}
          endAngle={130}
          width={400}
          height={400}
          data={getData(acwrDisplay)}
          innerRadius={120}
          cornerRadius={25}
          labels={() => null}
          style={{
            data: {
              fill: ({datum}) => {
                const color = getLoadValueColor(datum.y);
                return datum.x === 1 ? color : '#fff';
              },
            },
          }}
        />
        <VictoryAnimation duration={1000} data={getData(acwrDisplay)}>
          {() => {
            return (
              <VictoryLabel
                textAnchor="middle"
                verticalAnchor="middle"
                x={200}
                y={200}
                text={(acwrDisplay ?? 0).toFixed(1)}
                style={{
                  fontSize: 100,
                  fill: '#fff',
                  fontWeight: 700,
                  fontFamily: 'Roboto Condensed',
                }}
              />
            );
          }}
        </VictoryAnimation>
      </svg>
    </Box>
  );
};

export default PlayerACWRGasGauge;
