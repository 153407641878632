import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import React, {useMemo, useState} from 'react';
import ProfileIdentity from './ProfileIdentity';
import {getMean, getStandardDeviation} from '../../services/mathService';
import {positions} from '../../services/teamService';
import {compare} from '../../helpers';
import TeamShiftUtilizationRow from './TeamShiftUtilizationRow';
import {PlayerSessionShift, SessionTag, TeamSession} from '../../interfaces';
import {useWindowSize} from '../../hooks/useWindowSize';
import PlayerNumber from './PlayerNumber';

interface PlayerPeriodData {
  playerId: number;
  number: number;
  firstName: string;
  lastName: string;
  position: string;
  profilePictureFileKey: string;
  errorState?: 'pendingUpload' | 'noData';
  timeOnIce: number;
  strideTime: number;
  shiftCount: number;
  energyRatio: number;
  baselineEnergyRatio: number;
}

export enum Metric {
  timeOnIce = 'timeOnIce',
  strideTime = 'strideTime',
  energyRatio = 'energyRatio',
  shiftCount = 'shiftCount',
}

type UtilizationData = Record<Metric, number>;

export interface PlayerData {
  playerId: number;
  number?: number;
  firstName: string;
  lastName: string;
  position: string;
  profilePictureFileKey: string;
  fullGameErrorState?: 'pendingUpload' | 'noData';
  fullGameTimeOnIce: number;
  fullGameStrideTime: number;
  fullGameShiftCount: number;
  fullGameEnergyRatio: number;
  fullGameUtilization: UtilizationData;
  baselineEnergyRatio: number;
  period1ErrorState?: 'pendingUpload' | 'noData';
  period1TimeOnIce: number;
  period1StrideTime: number;
  period1ShiftCount: number;
  period1EnergyRatio: number;
  period1Utilization: UtilizationData;
  period2ErrorState?: 'pendingUpload' | 'noData';
  period2TimeOnIce: number;
  period2StrideTime: number;
  period2ShiftCount: number;
  period2EnergyRatio: number;
  period2Utilization: UtilizationData;
  period3ErrorState?: 'pendingUpload' | 'noData';
  period3TimeOnIce: number;
  period3StrideTime: number;
  period3ShiftCount: number;
  period3EnergyRatio: number;
  period3Utilization: UtilizationData;
  overtimeErrorState?: 'pendingUpload' | 'noData';
  overtimeTimeOnIce: number;
  overtimeStrideTime: number;
  overtimeShiftCount: number;
  overtimeEnergyRatio: number;
  overtimeUtilization: UtilizationData;
}

const createSessionTagFilter = (sessionTagName: string) => {
  return (teamSession: TeamSession) =>
    (teamSession.sessionTags || []).filter(
      (sessionTag: SessionTag) => sessionTag.code === sessionTagName,
    ).length > 0;
};

const getPeriodData = ({
  periodTeamSessions,
}: {
  periodTeamSessions: TeamSession[];
}) => {
  const periodData: PlayerPeriodData[] = [];
  for (let i = 0; i < periodTeamSessions.length; i++) {
    const teamSession = periodTeamSessions[i];
    if (!teamSession.playerSessions) {
      continue;
    }
    for (let j = 0; j < teamSession.playerSessions.length; j++) {
      const playerSession = teamSession.playerSessions[j];
      const playerSessionShifts = (
        teamSession.playerSessions[j]?.playerSessionShifts ?? []
      ).filter((shift: PlayerSessionShift) => shift.valid);
      const currentPlayer = periodData.filter(
        x => x.playerId === playerSession?.player?.id,
      );
      if (currentPlayer.length === 1) {
        currentPlayer[0].timeOnIce += playerSessionShifts.reduce(
          (acc: number, shift: PlayerSessionShift) =>
            (acc += (shift.end || 0) - (shift.start || 0)),
          0,
        );
        currentPlayer[0].strideTime += playerSessionShifts.reduce(
          (acc: number, shift: PlayerSessionShift) =>
            (acc += shift.strintTotalTime || 0),
          0,
        );
        currentPlayer[0].shiftCount += playerSessionShifts.length;
      } else {
        periodData.push({
          playerId: playerSession?.player?.id || 0,
          number: playerSession?.player?.number || 0,
          firstName: playerSession?.player?.profile?.firstName || '',
          lastName: playerSession?.player?.profile?.lastName || '',
          position: playerSession?.player?.profile?.position || '',
          profilePictureFileKey:
            playerSession?.player?.profile?.profilePictureFileKey || '',
          errorState: playerSession
            ? playerSession.hasPlayerData
              ? undefined
              : 'pendingUpload'
            : 'noData',
          timeOnIce: playerSessionShifts.reduce(
            (acc: number, shift: PlayerSessionShift) =>
              (acc += (shift.end || 0) - (shift.start || 0)),
            0,
          ),
          strideTime: playerSessionShifts.reduce(
            (acc: number, shift: PlayerSessionShift) =>
              (acc += shift.strintTotalTime || 0),
            0,
          ),
          shiftCount: playerSessionShifts.length,
          energyRatio: 0,
          baselineEnergyRatio: playerSession?.baseline?.energyRatio || 0,
        });
      }
    }
  }
  return periodData.map(playerPeriodData => {
    if (playerPeriodData.timeOnIce > 0) {
      playerPeriodData.energyRatio =
        playerPeriodData.strideTime / playerPeriodData.timeOnIce;
    }
    return playerPeriodData;
  });
};

const getUtilization = (
  periodData: PlayerPeriodData[],
  playerData: PlayerPeriodData,
  metric: Metric,
) => {
  const non0Data = periodData
    .map(player => {
      if (metric === 'timeOnIce') {
        return player.timeOnIce;
      } else if (metric === 'strideTime') {
        return player.strideTime;
      } else if (metric === 'shiftCount') {
        return player.shiftCount;
      } else if (metric === 'energyRatio') {
        return player.energyRatio;
      }
      return 0;
    })
    .filter(metric => metric > 0);

  if (non0Data.length > 0) {
    const non0Mean = getMean(non0Data);
    const non0StdDev = getStandardDeviation(non0Data);
    if (non0Mean !== null && non0StdDev !== null) {
      const non02StdDevMin = non0Mean - 2 * non0StdDev;
      const non02StdDevMax = non0Mean + 2 * non0StdDev;
      return non0Mean > 0 && playerData?.timeOnIce > 0
        ? ((playerData?.[metric] ?? 0) - non02StdDevMin) /
            (non02StdDevMax - non02StdDevMin)
        : 0;
    }
  }

  return 0;
};

const UtilizationRow = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
}));

const UtilizationCell = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 10,
}));

interface Props {
  teamSessions: TeamSession[];
}

const TeamShiftUtilization: React.FC<Props> = ({teamSessions}) => {
  const [metric, setMetric] = useState<
    'timeOnIce' | 'strideTime' | 'shiftCount' | 'energyRatio' | 'fullView'
  >('fullView');
  let fullGameTeamSessions = teamSessions.filter(
    createSessionTagFilter('FULL_GAME'),
  );
  const period1TeamSessions = teamSessions.filter(
    createSessionTagFilter('PERIOD_1'),
  );
  const period2TeamSessions = teamSessions.filter(
    createSessionTagFilter('PERIOD_2'),
  );
  const period3TeamSessions = teamSessions.filter(
    createSessionTagFilter('PERIOD_3'),
  );
  const overtimeTeamSessions = teamSessions.filter(
    createSessionTagFilter('OVERTIME'),
  );

  if (
    fullGameTeamSessions.length === 0 &&
    period1TeamSessions.length === 0 &&
    period2TeamSessions.length === 0 &&
    period3TeamSessions.length === 0 &&
    overtimeTeamSessions.length === 0 &&
    teamSessions.length === 1 &&
    teamSessions[0].type === 'game'
  ) {
    fullGameTeamSessions = teamSessions;
  }

  const fullGameData = getPeriodData({
    periodTeamSessions: fullGameTeamSessions,
  });
  const period1Data = getPeriodData({
    periodTeamSessions: period1TeamSessions,
  });
  const period2Data = getPeriodData({
    periodTeamSessions: period2TeamSessions,
  });
  const period3Data = getPeriodData({
    periodTeamSessions: period3TeamSessions,
  });
  const overtimeData = getPeriodData({
    periodTeamSessions: overtimeTeamSessions,
  });

  const players = useMemo(
    () => [
      ...fullGameData,
      ...period1Data,
      ...period2Data,
      ...period3Data,
      ...overtimeData,
    ],
    [fullGameData, overtimeData, period1Data, period2Data, period3Data],
  );

  // order players by position
  // ignore goalies from utilization
  const playerIds = players
    .filter(
      player =>
        positions.includes(player.position) && player.position !== 'goalie',
    )
    .sort((a: PlayerPeriodData, b: PlayerPeriodData) => {
      return (
        compare(positions.indexOf(a.position), positions.indexOf(b.position)) ||
        compare(a.lastName, b.lastName)
      );
    })
    .map(player => player.playerId);

  const uniquePlayerIds = useMemo(() => [...new Set(playerIds)], [playerIds]);

  const fullGamePlayerUtilization: PlayerData[] = useMemo(
    () =>
      uniquePlayerIds.map(playerId => {
        const activePlayer = players.filter(
          player => player.playerId === playerId,
        )[0];
        const fullGamePlayer = fullGameData.filter(
          player => player.playerId === playerId,
        )[0];
        const period1Player = period1Data.filter(
          player => player.playerId === playerId,
        )[0];
        const period2Player = period2Data.filter(
          player => player.playerId === playerId,
        )[0];
        const period3Player = period3Data.filter(
          player => player.playerId === playerId,
        )[0];
        const overtimePlayer = overtimeData.filter(
          player => player.playerId === playerId,
        )[0];

        return {
          playerId,
          firstName: activePlayer?.firstName,
          lastName: activePlayer?.lastName,
          position: activePlayer?.position,
          profilePictureFileKey: activePlayer?.profilePictureFileKey,
          fullGameErrorState: fullGamePlayer
            ? fullGamePlayer?.errorState
            : 'noData',
          fullGameTimeOnIce: fullGamePlayer?.timeOnIce ?? 0,
          fullGameStrideTime: fullGamePlayer?.strideTime ?? 0,
          fullGameShiftCount: fullGamePlayer?.shiftCount ?? 0,
          fullGameEnergyRatio: fullGamePlayer?.energyRatio ?? 0,
          fullGameUtilization: {
            timeOnIce: getUtilization(
              fullGameData,
              fullGamePlayer,
              Metric.timeOnIce,
            ),
            strideTime: getUtilization(
              fullGameData,
              fullGamePlayer,
              Metric.strideTime,
            ),
            shiftCount: getUtilization(
              fullGameData,
              fullGamePlayer,
              Metric.shiftCount,
            ),
            energyRatio: getUtilization(
              fullGameData,
              fullGamePlayer,
              Metric.energyRatio,
            ),
          },
          baselineEnergyRatio: activePlayer.baselineEnergyRatio,
          period1ErrorState: period1Player
            ? period1Player.errorState
            : 'noData',
          period1TimeOnIce: period1Player?.timeOnIce ?? 0,
          period1StrideTime: period1Player?.strideTime ?? 0,
          period1ShiftCount: period1Player?.shiftCount ?? 0,
          period1EnergyRatio: period1Player?.energyRatio ?? 0,
          period1Utilization: {
            timeOnIce: getUtilization(
              period1Data,
              period1Player,
              Metric.timeOnIce,
            ),
            strideTime: getUtilization(
              period1Data,
              period1Player,
              Metric.strideTime,
            ),
            shiftCount: getUtilization(
              period1Data,
              period1Player,
              Metric.shiftCount,
            ),
            energyRatio: getUtilization(
              period1Data,
              period1Player,
              Metric.energyRatio,
            ),
          },
          period2ErrorState: period2Player
            ? period2Player?.errorState
            : 'noData',
          period2TimeOnIce: period2Player?.timeOnIce ?? 0,
          period2StrideTime: period2Player?.strideTime ?? 0,
          period2ShiftCount: period2Player?.shiftCount ?? 0,
          period2EnergyRatio: period2Player?.energyRatio ?? 0,
          period2Utilization: {
            timeOnIce: getUtilization(
              period2Data,
              period2Player,
              Metric.timeOnIce,
            ),
            strideTime: getUtilization(
              period2Data,
              period2Player,
              Metric.strideTime,
            ),
            shiftCount: getUtilization(
              period2Data,
              period2Player,
              Metric.shiftCount,
            ),
            energyRatio: getUtilization(
              period2Data,
              period2Player,
              Metric.energyRatio,
            ),
          },
          period3ErrorState: period3Player
            ? period3Player?.errorState
            : 'noData',
          period3TimeOnIce: period3Player?.timeOnIce ?? 0,
          period3StrideTime: period3Player?.strideTime ?? 0,
          period3ShiftCount: period3Player?.shiftCount ?? 0,
          period3EnergyRatio: period3Player?.energyRatio ?? 0,
          period3Utilization: {
            timeOnIce: getUtilization(
              period3Data,
              period3Player,
              Metric.timeOnIce,
            ),
            strideTime: getUtilization(
              period3Data,
              period3Player,
              Metric.strideTime,
            ),
            shiftCount: getUtilization(
              period3Data,
              period3Player,
              Metric.shiftCount,
            ),
            energyRatio: getUtilization(
              period3Data,
              period3Player,
              Metric.energyRatio,
            ),
          },
          overtimeErrorState: overtimePlayer
            ? overtimePlayer?.errorState
            : 'noData',
          overtimeTimeOnIce: overtimePlayer?.timeOnIce ?? 0,
          overtimeStrideTime: overtimePlayer?.strideTime ?? 0,
          overtimeShiftCount: overtimePlayer?.shiftCount ?? 0,
          overtimeEnergyRatio: overtimePlayer?.energyRatio ?? 0,
          overtimeUtilization: {
            timeOnIce: getUtilization(
              overtimeData,
              overtimePlayer,
              Metric.timeOnIce,
            ),
            strideTime: getUtilization(
              overtimeData,
              overtimePlayer,
              Metric.strideTime,
            ),
            shiftCount: getUtilization(
              overtimeData,
              overtimePlayer,
              Metric.shiftCount,
            ),
            energyRatio: getUtilization(
              overtimeData,
              overtimePlayer,
              Metric.energyRatio,
            ),
          },
        };
      }),
    [
      fullGameData,
      overtimeData,
      period1Data,
      period2Data,
      period3Data,
      players,
      uniquePlayerIds,
    ],
  );

  const expandPlayerNumber = useMemo(
    () =>
      (fullGamePlayerUtilization ?? []).some(
        player => (player.number ?? 0) > 0,
      ),
    [fullGamePlayerUtilization],
  );

  const [width] = useWindowSize();

  if (
    fullGameTeamSessions.length > 0 ||
    period1TeamSessions.length > 0 ||
    period2TeamSessions.length > 0 ||
    period3TeamSessions.length > 0 ||
    overtimeTeamSessions.length > 0
  ) {
    return (
      <>
        <Button
          sx={{mr: 2, mb: 2}}
          variant={metric === 'fullView' ? 'contained' : 'outlined'}
          onClick={() => setMetric('fullView')}>
          Full View
        </Button>
        <Button
          sx={{mr: 2, mb: 2}}
          variant={metric === Metric.timeOnIce ? 'contained' : 'outlined'}
          onClick={() => setMetric(Metric.timeOnIce)}>
          Time On Ice
        </Button>
        <Button
          sx={{mr: 2, mb: 2}}
          variant={metric === Metric.strideTime ? 'contained' : 'outlined'}
          onClick={() => setMetric(Metric.strideTime)}>
          Stride Time
        </Button>
        <Button
          sx={{mr: 2, mb: 2}}
          variant={metric === Metric.energyRatio ? 'contained' : 'outlined'}
          onClick={() => setMetric(Metric.energyRatio)}>
          Energy Ratio
        </Button>
        <Button
          sx={{mr: 2, mb: 2}}
          variant={metric === Metric.shiftCount ? 'contained' : 'outlined'}
          onClick={() => setMetric(Metric.shiftCount)}>
          Shift Count
        </Button>
        <TableContainer sx={{mb: 5}} component={Paper}>
          <Table
            aria-label="simple table"
            padding="none"
            sx={{minWidth: '700px'}}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell sx={{width: '100%', paddingRight: 0}}>
                  <UtilizationRow>
                    {fullGameTeamSessions.length > 0 ? (
                      <UtilizationCell>
                        <Typography variant="h4">Full Game</Typography>
                      </UtilizationCell>
                    ) : null}
                    {period1TeamSessions.length > 0 ? (
                      <UtilizationCell>
                        <Typography variant="h4">Period 1</Typography>
                      </UtilizationCell>
                    ) : null}
                    {period2TeamSessions.length > 0 ? (
                      <UtilizationCell>
                        <Typography variant="h4">Period 2</Typography>
                      </UtilizationCell>
                    ) : null}
                    {period3TeamSessions.length > 0 ? (
                      <UtilizationCell>
                        <Typography variant="h4">Period 3</Typography>
                      </UtilizationCell>
                    ) : null}
                    {overtimeTeamSessions.length > 0 ? (
                      <UtilizationCell>
                        <Typography variant="h4">Overtime</Typography>
                      </UtilizationCell>
                    ) : null}
                  </UtilizationRow>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fullGamePlayerUtilization.map(player => (
                <TableRow key={player.playerId} sx={{height: '1px'}}>
                  <TableCell sx={{whiteSpace: 'nowrap', padding: '3px'}}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        pl: 1,
                      }}>
                      <PlayerNumber
                        number={player.number}
                        expandIfEmpty={expandPlayerNumber}
                      />
                      <ProfileIdentity
                        firstName={width > 1200 ? player.firstName : ''}
                        lastName={player.lastName}
                        position={player.position}
                        profilePictureUrl={player.profilePictureFileKey}
                        size="small"
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '100%',
                      height: 'inherit',
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingRight: 0,
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}>
                      <TeamShiftUtilizationRow
                        player={player}
                        selectedMetric={metric}
                        fullGameTeamSessions={fullGameTeamSessions}
                        period1TeamSessions={period1TeamSessions}
                        period2TeamSessions={period2TeamSessions}
                        period3TeamSessions={period3TeamSessions}
                        overtimeTeamSessions={overtimeTeamSessions}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
  return null;
};

export default TeamShiftUtilization;
