import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Baseline} from '../interfaces';
import {getBaselines} from '../api/baselineAPI';

export interface BaselineState {
  baselines: Baseline[];
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: BaselineState = {
  baselines: [],
  status: 'idle',
  error: null,
};

const getBaselinesAsync = createAsyncThunk(
  'baseline/getBaselines',
  async (payload: {profileId: number; types: string[]}, {rejectWithValue}) => {
    try {
      const response: any = await getBaselines(
        payload.profileId,
        payload.types,
      );
      return response.data?.data?.baselines || [];
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const baselineSlice = createSlice({
  name: 'baseline',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBaselinesAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(getBaselinesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.baselines = action.payload;
        state.error = null;
      })
      .addCase(getBaselinesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.baselines = [];
        state.error = action.payload;
      });
  },
});

export default baselineSlice.reducer;

export {getBaselinesAsync};
