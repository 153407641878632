import {DateTime} from 'luxon';

const compare = (a: any, b: any) => {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
};

const isNullOrUndefined = (value: unknown) =>
  value === null || value === undefined;

const getPreviousISODates = (
  startDate: Date = new Date(),
  numberOfDays: number = 30,
) => {
  const dates = [];

  for (let i = 0; i < numberOfDays; i++) {
    // Create a new date object for each day in the past
    const pastDate = new Date(startDate);
    pastDate.setDate(startDate.getDate() - i);

    // Convert the date to ISO format and add it to the array
    dates.push(DateTime.fromJSDate(pastDate).toFormat('yyyy-MM-dd'));
  }

  return dates;
};

export {compare, isNullOrUndefined, getPreviousISODates};
