export const COLOR_DANGER = '#F22814';
export const COLOR_WARNING = '#F28E14';
export const COLOR_SUCCESS = '#34C759';

export const COLOR_GRAY_TRANSPARENCY = 'rgba(116, 116, 128, 0.18)';

export const CONTAINER_PADDING = 10;
export const CARD_BORDER_RADIUS = 6;
export const BACKGROUND_COLOR = '#000000'; // 'rgba(216,216,216,0.81)';
export const BORDER_COLOR = '#FFFFFF';
export const BUTTON_BACKGROUND_COLOR = 'rgba(29,29,29,0.5)';
export const TEXT_COLOR_GRAY = '#D8D8D8';
export const COLOR_GRAY_MEDIUM = 'rgba(235,235,245,0.6)';
export const UNDERLINE_BORDER_COLOR = 'rgba(53,59,62,0.75)';
export const TAB_BAR_COLOR = '#1C1C1E';
export const PROGRESS_BAR_BG_COLOR = '#535C61';
export const TEXT_COLOR_LIGHT_GRAY = '#FFFFFF99';
export const TEXT_COLOR = '#EBEBF599';
export const WHITE_COLOR = '#FFFFFF';
export const ORANGE_COLOR = '#F25F14';
export const TILE_COLOR = '#7474802E';
export const GRAY_BG_COLOR = '#7474802E';
export const BLACK_COLOR_121213 = '#121213';
export const GRAY_COLOR_C3C4C4 = '#C3C4C4';
export const BROWN_COLOR_2E1204 = '#2E1204';
export const GRAPH_GREEN = '#1DB100';
export const GRAPH_RED = '#EE220C';
export const GRAPH_YELLOW = '#FEAE00';
export const SKY_BLUE = '#1C98ED';
export const TEAL = '#40E0D0';
export const VIVID_VIOLET = '#8A2BE2';

const styles = {
  // helpers
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  textCenter: {
    textAlign: 'center',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  flexDirectionRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  flexGrow: {
    flexGrow: 1,
  },
  paddingTop: {
    paddingTop: '10px',
  },
  paddingBottom: {
    paddingBottom: '10px',
  },
  marginTop: {
    marginTop: '10px',
  },
  marginBottom: {
    marginBottom: '10px',
  },
  marginLeft: {
    marginLeft: '10px',
  },
  marginRight: {
    marginRight: '10px',
  },
  marginSmallTop: {
    marginTop: '5px',
  },
  marginSmallBottom: {
    marginBottom: '5px',
  },
  marginSmallLeft: {
    marginLeft: '5px',
  },
  marginSmallRight: {
    marginRight: '5px',
  },
  aspectRatioSquare: {
    aspectRatio: 1,
  },
  textTransformUpper: {
    textTransform: 'uppercase',
  },
  textTransformCapitalize: {
    textTransform: 'capitalize',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
    overflowY: 'auto',
    width: '90%',
    maxWidth: 1000,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    border: '2px solid #fff',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  },
};

export default styles;

export {
  CONTAINER_PADDING as paddingContainer,
  TEXT_COLOR_GRAY as textColorGray,
  COLOR_GRAY_MEDIUM as colorGrayMedium,
  TAB_BAR_COLOR as tabBarColor,
  PROGRESS_BAR_BG_COLOR as progressBarBgColor,
  COLOR_DANGER as colorDanger,
  COLOR_WARNING as colorWarning,
  COLOR_SUCCESS as colorSuccess,
  COLOR_GRAY_TRANSPARENCY as colorGrayTransparency,
  TEXT_COLOR as TextColor,
  ORANGE_COLOR as OrangeColor,
  TILE_COLOR as TileColor,
  GRAY_BG_COLOR as GrayBgColor,
  BLACK_COLOR_121213 as BlackColor121213,
  GRAY_COLOR_C3C4C4 as GrayColorC3C4C4,
  BROWN_COLOR_2E1204 as BrownColor2E1204,
};
