const getMean = (array: number[]) => {
  const n = array.length;
  if (n === 0) {
    return null;
  }
  const mean = array.reduce((a, b) => a + b) / n;
  return mean || 0;
};

const getStandardDeviation = (array: number[]) => {
  const n = array.length;
  const mean = getMean(array);
  if (n === 0 || mean === null) {
    return null;
  }
  return Math.sqrt(
    array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n,
  );
};

// const getMax = (array: number[]) => {
//   if (array.filter(x => x || x === 0).length === 0) {
//     return null;
//   }

//   const max = array.reduce(function(a, b) {
//     return Math.max(a, b);
//   }, null);

//   return max;
// }

const getHarmonicMean = (array: number[]) => {
  // Declare sum variables and initialize
  // with zero.
  let sum = 0;
  const n = array.length;

  for (let i = 0; i < n; i++) sum = sum + 1 / array[i];

  return n / sum;
};

const getGeometricMean = (array: number[]) => {
  // declare product variable and
  // initialize it to 1.
  let product = 1;
  const n = array.length;

  // Compute the product of all the
  // elements in the array.
  for (let i = 0; i < n; i++) product = product * array[i];

  // compute geometric mean through
  // formula pow(product, 1/n) and
  // return the value to main function.
  const gm = Math.pow(product, 1 / n);
  return gm;
};

const getSum = (arr: number[]) => {
  return arr.reduce((acc, c) => acc + c, 0);
};

const createTrend = (data: any[], xKey: string, yKey: string) => {
  const xData = data.map(value => value[xKey]);
  const yData = data.map(value => value[yKey]);

  // average of X values and Y values
  const xMean = getMean(xData) || 0;
  const yMean = getMean(yData) || 0;

  // Subtract X or Y mean from corresponding axis value
  const xMinusxMean = xData.map(val => val - xMean);
  const yMinusyMean = yData.map(val => val - yMean);

  const xMinusxMeanSq = xMinusxMean.map(val => Math.pow(val, 2));

  const xy = [];
  for (let x = 0; x < data.length; x++) {
    xy.push(xMinusxMean[x] * yMinusyMean[x]);
  }

  // const xy = xMinusxMean.map((val, index) => val * yMinusyMean[index]);

  const xySum = getSum(xy);
  const xMinusxMeanSqSum = getSum(xMinusxMeanSq);

  // b1 is the slope
  const b1 = xMinusxMeanSqSum ? xySum / xMinusxMeanSqSum : 0;
  // b0 is the start of the slope on the Y axis
  const b0 = yMean - b1 * xMean;

  return {
    slope: b1,
    yStart: b0,
    calcY: (x: number) => b0 + b1 * x,
  };
};

const setDecimalPlaces = (value: number, decimalPlaces: number) => {
  return parseFloat(value.toFixed(decimalPlaces));
};

const truncateToDecimalPlaces = (num: number, decimalPlaces: number) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.floor(num * factor) / factor;
};

export {
  getMean,
  getStandardDeviation,
  // getMax,
  getHarmonicMean,
  getGeometricMean,
  createTrend,
  setDecimalPlaces,
  truncateToDecimalPlaces,
};
