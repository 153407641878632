/* eslint-disable no-unused-vars */
export enum MembershipLevels {
  rec = 'rec',
  eliteMonth = 'elite_month',
  eliteYear = 'elite_year',
  proMonth = 'pro_month',
  proYear = 'pro_year',
  pro24Months = 'pro_24month',
}

export enum CurrentMembershipLevels {
  rec = 'rec',
  elite = 'elite',
  pro = 'pro',
}

export enum Roles {
  admin = 'admin',
  player = 'player',
  pro = 'pro',
  parent = 'parent',
  coach = 'coach',
  trainer = 'trainer',
}

export enum DayNames {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export enum SessionTypes {
  game = 'game',
  practice = 'practice',
  skills = 'skills',
}

export enum VideoTypes {
  ios = 'ios',
  android = 'android',
  veo = 'veo',
  pixellot = 'pixellot',
  scorecam = 'scorecam',
  livebarn = 'livebarn',
  mnhockeytv = 'mnhockeytv',
  flohockey = 'flohockey',
  mp4 = 'mp4',
  mov = 'mov',
}

export enum TeamSessionExportTypes {
  csvPlayers = 'csvPlayers',
  csvShifts = 'csvShifts',
  hudlSportscode = 'hudlSportscode',
  spiideoPerform = 'spiideoPerform',
  catapultThunder = 'catapultThunder',
}

export enum PositionGroup {
  forwards = 'forwards',
  defense = 'defense',
}

export enum Genders {
  male = 'male',
  female = 'female',
  unknown = 'unknown',
}

export enum Positions {
  goalie = 'goalie',
  defense = 'defense',
  center = 'center',
  leftwing = 'leftwing',
  rightwing = 'rightwing',
}
