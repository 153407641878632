import React, {useRef, useState} from 'react';
import {PlayerSessionShift} from '../../interfaces';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import PlayerSessionShiftSlider from '../PlayerSessions/PlayerSessionShiftSlider';

interface Props {
  shiftClips: PlayerSessionShift[];
}

const SessionVideoPlayer: React.FC<Props> = ({shiftClips}) => {
  const [videoIndex, setVideoIndex] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  return (
    <>
      <VideoPlayer
        videoRef={videoRef}
        sourceUrl={shiftClips?.[videoIndex]?.playerSessionVideoClips?.[0]?.url}
        shiftClips={shiftClips}
        videoIndex={videoIndex}
        setVideoIndex={setVideoIndex}
      />
      <PlayerSessionShiftSlider
        shiftClips={shiftClips}
        videoIndex={videoIndex}
        setVideoIndex={setVideoIndex}
        videoRef={videoRef}
      />
    </>
  );
};

export default SessionVideoPlayer;
