import React, {useMemo} from 'react';
import {Alert, Box, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ORANGE_COLOR} from '../../../styles';
import {VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip} from 'victory';
import {AcwrScore} from '../../../interfaces';
import PlayerACWRGasGauge from './PlayerACWRGasGauge';
import {getLoadValueColor, toolTipStyle} from '../../../services/graphService';
import {getPreviousISODates} from '../../../helpers';
import {DateTime} from 'luxon';
import {ACWR_MAX} from '../../../constants';

interface Props {
  acwrScores: AcwrScore[];
}

const PlayerLoadRow: React.FC<Props> = ({acwrScores}) => {
  const {t} = useTranslation();

  // only show acwr if its from the last 2 days
  const currentAcwr = useMemo(() => {
    const mostRecentAcwrScore = [...(acwrScores ?? [])].sort(
      (a, b) =>
        new Date(b.date as string).getTime() -
        new Date(a.date as string).getTime(),
    )?.[0];

    if (
      mostRecentAcwrScore?.date &&
      new Date(mostRecentAcwrScore?.date) >
        new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)
    ) {
      return mostRecentAcwrScore.acwrScore;
    }
    return null;
  }, [acwrScores]);

  const acwrHistory = useMemo(() => {
    // get the most recent 30 days
    const dates = getPreviousISODates(new Date(), 30);

    return dates
      .map(date => {
        let acwrDisplay = acwrScores.find(
          acwr => (acwr.date as string) === date,
        )?.acwrScore;
        if ((acwrDisplay ?? 0) > ACWR_MAX) {
          acwrDisplay = ACWR_MAX;
        }
        return {
          x: date,
          y: acwrDisplay ?? null,
        };
      })
      .reverse();
  }, [acwrScores]);

  return (
    <Box sx={{marginLeft: 2, marginRight: 2}}>
      <Typography sx={{color: ORANGE_COLOR}} variant="h5">
        {t('statistics.playerLoad')}
      </Typography>
      <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: 240,
            }}>
            <PlayerACWRGasGauge acwr={currentAcwr ?? 0} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: 240,
            }}>
            <Box>
              <VictoryChart
                height={180}
                padding={{top: 20, bottom: 5, left: 20, right: 20}}>
                <VictoryBar
                  width={undefined}
                  height={180}
                  barRatio={0.7}
                  cornerRadius={{top: 5, bottom: 5}}
                  data={acwrHistory}
                  labels={({datum}) =>
                    `${DateTime.fromISO(datum.xName).toFormat('MM/dd')}: ${datum._y.toFixed(1)} Load`
                  }
                  labelComponent={<VictoryTooltip style={toolTipStyle} />}
                  style={{
                    data: {
                      fill: ({datum}) => getLoadValueColor(datum.y),
                    },
                  }}
                />
                <VictoryAxis
                  tickValues={acwrScores.map((_, index) => index)}
                  tickFormat={() => '|'}
                  style={{
                    axis: {stroke: 'none'},
                    ticks: {stroke: '#fff', size: 5},
                    tickLabels: {fill: 'none'},
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={() => null}
                  style={{
                    axis: {stroke: 'none'},
                  }}
                />
              </VictoryChart>
              <Typography sx={{textAlign: 'end'}} variant="h5">
                {t('general.today')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Alert severity="info" variant="outlined" sx={{mb: 2}}>
        {t('statistics.playerLoadInfo')}
      </Alert>
    </Box>
  );
};

export default PlayerLoadRow;
