import React from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from 'victory';
import {Box} from '@mui/material';
import {SizeMe} from 'react-sizeme';
import {createTrend} from '../../../services/mathService';
import {GRAPH_YELLOW, ORANGE_COLOR} from '../../../styles';
import {
  getWeekTickFormatFunction,
  toolTipStyle,
  xAxisStyle,
  xAxisWithTicksStyle,
  yAxisStyle,
} from '../../../services/graphService';
import {useTranslation} from 'react-i18next';

interface Props {
  gameData: number[];
  practiceData: number[];
  allData: number[];
  metric: string;
  range: string;
  decimals: number;
  dataValue: string;
}

const TotalEffortLineChart: React.FC<Props> = props => {
  const {t} = useTranslation();
  const {gameData, practiceData, decimals, range, metric, dataValue, allData} =
    props;

  const trendData1 = createTrend(
    allData
      .map((point, index) => {
        if (point === 0) {
          return null;
        }
        return {
          x: index,
          y: point,
        };
      })
      .filter(x => x),
    'x',
    'y',
  );

  const trendDataSlopeLength =
    gameData.length > practiceData.length
      ? gameData.length
      : practiceData.length;

  return (
    <Box>
      <SizeMe>
        {({size}) => (
          <VictoryChart
            width={size.width ? size.width - 20 : 1200}
            padding={{left: 30, right: 70, top: 50, bottom: 50}}
            domainPadding={{x: 10}}>
            <VictoryGroup>
              <VictoryLine
                data={practiceData
                  .map((point, index) => {
                    if (point === 0) {
                      return null;
                    }
                    return {
                      x: index,
                      y: point,
                    };
                  })
                  .filter(x => x)}
                style={{data: {stroke: GRAPH_YELLOW}}}
              />
              <VictoryScatter
                data={practiceData.map(point => {
                  if (point === 0) {
                    return null;
                  }
                  return point;
                })}
                style={{data: {fill: GRAPH_YELLOW}}}
                size={2}
                // TODO why isn't there a type for this? I think this is a types error with Victory
                labels={({datum}) => `${datum._y}`}
                labelComponent={<VictoryTooltip style={toolTipStyle} />}
              />
              <VictoryLine
                data={gameData
                  .map((point, index) => {
                    if (point === 0) {
                      return null;
                    }
                    return {
                      x: index,
                      y: point,
                    };
                  })
                  .filter(x => x)}
                style={{data: {stroke: ORANGE_COLOR}}}
              />
              {trendData1.yStart && trendData1.slope ? (
                <VictoryLine
                  data={[
                    {
                      x: 0,
                      y: trendData1.yStart,
                    },
                    {
                      x: trendDataSlopeLength - 1,
                      y:
                        trendData1.slope * (trendDataSlopeLength - 1) +
                        trendData1.yStart,
                    },
                  ]}
                  style={{data: {stroke: 'white', opacity: 0.8}}}
                />
              ) : null}
              <VictoryScatter
                data={gameData.map(point => {
                  if (point === 0) {
                    return null;
                  }
                  return point;
                })}
                style={{data: {fill: ORANGE_COLOR}}}
                size={2}
                labels={({datum}) => `${datum._y}`}
                labelComponent={<VictoryTooltip style={toolTipStyle} />}
              />
            </VictoryGroup>
            {range === 'week' && (
              <VictoryAxis
                style={xAxisWithTicksStyle}
                tickValues={[...Array(7).keys()]}
                tickFormat={getWeekTickFormatFunction(t, false)}
              />
            )}
            {range === 'month' && (
              <VictoryAxis
                style={xAxisWithTicksStyle}
                tickValues={[...Array(31).keys()]}
                tickFormat={tick =>
                  [0, 6, 13, 20, 27].indexOf(tick) !== -1
                    ? tick + 1
                    : String.fromCharCode(8226)
                }
              />
            )}
            {range === 'allTime' && <VictoryAxis style={xAxisStyle} />}
            {dataValue === 'balanceNormalized' ? (
              <VictoryAxis
                dependentAxis
                tickValues={[0.05, 0.5, 0.95]}
                tickFormat={tick => {
                  if (tick === 0.05) {
                    return 'Right';
                  } else if (tick === 0.5) {
                    return 'Balanced';
                  } else if (tick === 0.95) {
                    return 'Left';
                  }

                  return '';
                }}
                orientation="right"
                style={yAxisStyle}
              />
            ) : (
              <VictoryAxis
                dependentAxis
                tickFormat={tick =>
                  tick.toFixed(decimals) !== (0).toFixed(decimals)
                    ? `${tick.toFixed(decimals)}${metric}`
                    : ''
                }
                orientation="right"
                style={yAxisStyle}
              />
            )}
          </VictoryChart>
        )}
      </SizeMe>
    </Box>
  );
};

export default TotalEffortLineChart;
