import {Box, Typography} from '@mui/material';
import React, {useMemo} from 'react';
import EfficiencyPolarChart from './EfficiencyPolarChart';
import {PlayerSession} from '../../../interfaces';
import {useTranslation} from 'react-i18next';
import {PositionGroup} from '../../../enums';
import {getUniqueName} from '../../../services/graphService';

interface Props {
  playerSessions: PlayerSession[];
  teamSessionStartTime: string;
  teamSessionEndTime: string;
  practiceEfficiencyMax?: number;
}

const PracticeEfficiency: React.FC<Props> = ({
  playerSessions,
  teamSessionStartTime,
  teamSessionEndTime,
  practiceEfficiencyMax,
}) => {
  const {t} = useTranslation();
  const {forwardData, defenseData} = useMemo(() => {
    const forwardData: {
      x: string;
      y: number;
    }[] = [];
    const defenseData: {
      x: string;
      y: number;
    }[] = [];
    const allPlayerNames = playerSessions.map(ps => ({
      firstName: ps.player?.profile?.firstName || 'Unknown',
      lastName: ps.player?.profile?.lastName || 'Unknown',
      profileId: ps.player?.profile?.id || 0,
    }));
    const totalSessionTime =
      (new Date(teamSessionEndTime).getTime() -
        new Date(teamSessionStartTime).getTime()) /
      1000;
    playerSessions.forEach(playerSession => {
      const playersPosition = playerSession.player?.profile?.position || '';
      const efficiencyValue =
        ((playerSession.strintTotalTime || 0) / totalSessionTime) * 100;
      const currentPlayerNameData = {
        firstName: playerSession.player?.profile?.firstName || 'Unknown',
        lastName: playerSession.player?.profile?.lastName || 'Unknown',
        profileId: playerSession.player?.profile?.id || 0,
      };
      const playerName = getUniqueName(currentPlayerNameData, allPlayerNames);
      const playerData = {
        x: playerName,
        y: efficiencyValue,
      };
      if (playersPosition === PositionGroup.defense) {
        defenseData.push(playerData);
      } else {
        forwardData.push(playerData);
      }
    });
    forwardData.sort((a, b) => (b.x < a.x ? 1 : -1));
    defenseData.sort((a, b) => (b.x < a.x ? 1 : -1));
    return {
      forwardData,
      defenseData,
    };
  }, [playerSessions, teamSessionEndTime, teamSessionStartTime]);
  // determine max practice efficiency, default to 25 if missing
  const max = useMemo(
    () => practiceEfficiencyMax ?? 25,
    [practiceEfficiencyMax],
  );
  return (
    <Box sx={{marginBottom: 10}}>
      <Box
        sx={{
          display: 'flex',
          marginTop: 10,
          marginBottom: 2,
          flexDirection: {xs: 'column', md: 'row'},
        }}>
        <EfficiencyPolarChart
          data={forwardData}
          practiceEfficiencyMax={max}
          positionGroup={PositionGroup.forwards}
        />
        <EfficiencyPolarChart
          data={defenseData}
          practiceEfficiencyMax={max}
          positionGroup={PositionGroup.defense}
        />
      </Box>
      <Typography variant="body1" sx={{textAlign: 'center'}}>
        {t('practiceEfficiency.info')}
      </Typography>
    </Box>
  );
};

export default PracticeEfficiency;
