import React, {useCallback, useEffect} from 'react';
import {createOrganization, getOrganizations} from '../../api/adminAPI';
import {Organization} from '../../interfaces';
import {Box, Button, Modal, Typography} from '@mui/material';
import PageTitle from '../shared/PageTitle';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import OrganizationsTable from './OrganizationsTable';
import styles from '../../styles';
import {useForm} from 'react-hook-form';
import FormTextField from '../shared/FormTextField';

interface Props {}

type FormValues = {
  name: string;
  country: string;
  stateOrProvince: string;
};

const AdminOrganizations: React.FC<Props> = () => {
  const {handleSubmit, reset, control} = useForm<FormValues>();
  const {t} = useTranslation();

  const [organizations, setOrganizations] = React.useState<Organization[]>([]);
  const [showAddDialog, setShowAddDialog] = React.useState(false);
  const [pending, setPending] = React.useState(false);

  const fetchData = useCallback(async () => {
    setPending(true);
    // fetch organizations
    const orgs = await getOrganizations();
    setOrganizations(orgs);
    setPending(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddDialogClick = useCallback(() => {
    setShowAddDialog(true);
  }, []);

  const handleAddDialogClose = useCallback(() => {
    reset();
    setPending(false);
    setShowAddDialog(false);
  }, [reset]);

  const handleAddSubmit = useCallback(
    async (data: FormValues) => {
      setPending(true);
      // create organization
      await createOrganization(data);
      fetchData();
      handleAddDialogClose();
    },
    [fetchData, handleAddDialogClose],
  );

  return (
    <>
      <Helmet>
        <title>
          {t('navigation.pageTitle', {pageName: t('pages.organizations')})}
        </title>
      </Helmet>
      <Box>
        <PageTitle>{t('pages.organizations')}</PageTitle>
        <Button onClick={handleAddDialogClick}>Create Organization</Button>
        <Modal
          open={showAddDialog}
          onClose={handleAddDialogClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={styles.modal}>
            <Typography variant="h4" sx={{mb: 2}}>
              Add Organization
            </Typography>
            <form onSubmit={handleSubmit(handleAddSubmit)} className="form">
              <FormTextField
                control={control}
                name="name"
                label="Name"
                required
              />
              <FormTextField
                control={control}
                name="country"
                label="Country"
                required
              />
              <FormTextField
                control={control}
                name="stateOrProvince"
                label="State or Province"
                required
              />
              <Button disabled={pending} type="submit">
                Submit
              </Button>
            </form>
          </Box>
        </Modal>
        <OrganizationsTable organizations={organizations ?? []} />
      </Box>
    </>
  );
};

export default React.memo(AdminOrganizations);
