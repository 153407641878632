import {Container} from '@mui/material';
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {getChildProfile} from '../../services/familyService';
import {getPlayerProfile} from '../../services/teamService';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {getPlayerSessionsAsync} from '../../store/playerSessionSlice';
import BackButton from '../shared/BackButton';
import PageTitle from '../shared/PageTitle';
import PlayerSessionsList from '../shared/PlayerSessionsList';
import ProfileIdentity from '../shared/ProfileIdentity';

interface Props {}

const PlayerSessions: React.FC<Props> = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const currentState = useAppSelector(state => ({
    playerSession: state.playerSession,
    family: state.family,
    team: state.team,
  }));

  const {profileId, teamId} = useParams();
  const profileIdInt = parseInt(profileId ?? '');
  const teamIdInt = parseInt(teamId ?? '');

  useEffect(() => {
    dispatch(
      getPlayerSessionsAsync({profileId: profileId ? profileIdInt : undefined}),
    );
  }, []);

  const title = 'Player Sessions';
  const childProfile = getChildProfile({
    family: currentState?.family?.families?.[0],
    profileId: profileIdInt,
  });
  const playerProfile = getPlayerProfile({
    team: (currentState?.team?.teams ?? []).filter(
      team => team.id === teamIdInt,
    )[0],
    profileId: profileIdInt,
  });

  let backText = t('navigation.back', {pageName: t('pages.dashboard')});
  let backUrl = '/';
  let navigateUrlPrefix = '';

  if (teamId) {
    backText = t('navigation.back', {pageName: t('pages.teamPlayers')});
    backUrl = `/teams/${teamId}/players`;
    navigateUrlPrefix = `/teams/${teamId}/players/${profileId}/playersessions`;
  } else if (profileId) {
    navigateUrlPrefix = `/family/children/${profileId}/playersessions`;
  }

  return (
    <>
      <Helmet>
        <title>
          {t('navigation.pageTitle', {pageName: t('pages.playerSessions')})}
        </title>
      </Helmet>
      <Container>
        <PageTitle>{title}</PageTitle>
        <BackButton navigationUrl={backUrl}>{backText}</BackButton>
        {childProfile && (
          <ProfileIdentity
            firstName={childProfile?.firstName}
            lastName={childProfile?.lastName}
            position={childProfile?.position}
            profilePictureUrl={childProfile?.profilePictureUrl}
          />
        )}
        {playerProfile && (
          <ProfileIdentity
            firstName={playerProfile?.firstName}
            lastName={playerProfile?.lastName}
            position={playerProfile?.position}
            profilePictureUrl={playerProfile?.profilePictureFileKey}
          />
        )}
        <PlayerSessionsList
          navigateUrlPrefix={navigateUrlPrefix}
          playerSessions={[
            ...(currentState.playerSession?.playerSessions || []),
          ]}
          showDate={true}
        />
      </Container>
    </>
  );
};

export default PlayerSessions;
