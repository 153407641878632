import {AxiosPromise} from 'axios';
import api from './api';
import apiGraphQL from './apiGraphQL';

export function createSessionVideo(params: {
  playerSessionId?: number;
  teamSessionId?: number;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post('/sessionvideos', params)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function createSessionVideoSegment(params: {
  sessionVideoId: number;
  fileName?: string;
  parts?: number;
  veoUrl?: string;
  pixellotUrl?: string;
  userSelectedStartTime?: Date;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post('/sessionvideosegments', params)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function createSessionVideoSegmentComplete(params: {
  sessionVideoId: number;
  key: string;
  uploadId: string;
  parts: any;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post('/sessionvideosegments/complete', params)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateSessionVideoSegment({
  input,
}: {
  input: {
    id: number;
    userSelectedStartTime?: Date;
  };
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `mutation UpdateSessionVideoSegment($input: UpdateSessionVideoSegmentInput!) {
            updateSessionVideoSegment(input: $input) {
              id
            }
          }`,
          variables: {
            input,
          },
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteSessionVideoSegment(params: {
  sessionVideoSegmentId: number;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .delete(`/sessionvideosegments/${params.sessionVideoSegmentId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function processSessionVideo(params: {sessionVideoId: number}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post(`/sessionvideos/${params.sessionVideoId}/process`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getPixellotVideoDetails({url}: {url: string}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `query ($url: String!) {
            getPixellotVideoDetails(url: $url) {
              url
              urlStream
            }
          }`,
          variables: {
            url,
          },
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
