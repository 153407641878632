import React, {useEffect, useState} from 'react';
import FullScreenLoadingSpinner from '../shared/FullScreenLoading';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {useLocation, useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import PageTitle from '../shared/PageTitle';
import {getPlayersTeamSessionGroupAsync} from '../../store/teamSessionGroupSlice';
import BackButton from '../shared/BackButton';
import SubtitleContainer from '../shared/SubtitleContainer';
import PageSubtitle from '../shared/PageSubtitle';
import {DateTime} from 'luxon';
import SessionGridItem from '../shared/SessionGridItem';
import {getTime} from '../../services/dateService';
import {getIntensityText} from '../../services/playerSessionService';
import {
  getSpeedMultiplier,
  getSpeedUnit,
} from '../../services/measurementService';
import SessionListItem from './SessionListItem';
import {SizeMe} from 'react-sizeme';
import {VictoryAxis} from 'victory';
import {yAxisStyle} from '../../services/graphService';
import TeamSessionGroupLineChart from './PlayerSessionGroupLineChart';
import {PlayerSessionShift} from '../../interfaces';
import PlayerSessionShifts from '../shared/PlayerSessionShifts';
import SessionVideoPlayer from '../shared/SessionVideoPlayer';
import {getPlayersTeamGroupSessionTotals} from '../../services/teamSessionGroupService';
import {Roles} from '../../enums';
import {getPlayerSessionGroupTag} from '../../services/playerSessionGroupService';

const ShiftTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface Props {
  playerView?: boolean;
}

const PlayerSessionGroupDetail: React.FC<Props> = ({playerView}) => {
  const {
    teamSessionGroupId,
    profileId,
    teamId: teamIdParam,
    teamSessionId,
  } = useParams();
  const location = useLocation();
  const hash = location.hash;
  console.log(hash);
  const [showShiftReview, setShowShiftReview] = useState<boolean>(false);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {teamSessionGroup, profile, family} = useAppSelector(state => ({
    teamSessionGroup: state.teamSessionGroup,
    profile: state.profile,
    family: state.family,
  }));

  let teamId = parseInt(teamIdParam ?? '');
  let backPath = '/';
  let backText = t('navigation.back', {pageName: t('pages.playerSessions')});
  if (profile?.profile?.role === Roles.parent) {
    backPath = `/family/children/${profileId}/playersessions`;
    backText = t('navigation.back', {pageName: t('pages.playerSessions')});
  } else if (teamId && profileId && hash === '#tsg') {
    backPath = `/teams/${teamId}/teamsessiongroups/${teamSessionGroupId}`;
    backText = t('navigation.back', {
      pageName: t('pages.teamSessionGroupDetail'),
    });
  } else if (teamId && profileId) {
    backPath = `/teams/${teamId}/players/${profileId}/playersessions`;
    backText = t('navigation.back', {pageName: t('pages.playerSessions')});
  } else if (teamId && teamSessionId) {
    backPath = `/teams/${teamId}/teamsessions/${teamSessionId}`;
    backText = t('navigation.back', {pageName: t('pages.teamSessionDetail')});
  }

  if (!teamId) {
    teamId = teamSessionGroup.teamSessionGroup?.teamSessions?.length
      ? teamSessionGroup.teamSessionGroup.teamSessions.find(ts => ts.teamId)
          ?.teamId || 0
      : 0;
  }

  const title = t('navigation.pageTitle', {
    pageName: t('pages.playerSessionGroupDetail'),
  });

  const StyledSessionType = styled('span')(() => ({
    display: 'block',
    textTransform: 'capitalize',
  }));

  useEffect(() => {
    // if profile is specified in the URL path
    if (teamSessionGroupId && profileId) {
      dispatch(
        getPlayersTeamSessionGroupAsync({
          id: parseInt(teamSessionGroupId),
          profileId: parseInt(profileId),
        }),
      );
    }
    // else if profile is specified in the profile state
    else if (teamSessionGroupId && profile?.profile?.id) {
      dispatch(
        getPlayersTeamSessionGroupAsync({
          id: parseInt(teamSessionGroupId),
          profileId: profile.profile.id,
        }),
      );
    }
  }, [teamSessionGroupId, profileId, dispatch, profile.profile.id]);

  const speedMultiplier = getSpeedMultiplier(
    profile?.profile?.preferredUnits === 'metric',
  );
  const speedUnit = getSpeedUnit(profile.profile?.preferredUnits === 'metric');

  if (
    teamSessionGroup.status === 'loading' ||
    !teamSessionGroup?.teamSessionGroup
  ) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <FullScreenLoadingSpinner />
      </>
    );
  }

  const {teamSessions} = teamSessionGroup.teamSessionGroup;

  const playerSessions = teamSessions?.flatMap(ts => ts.playerSessions || []);
  const type = teamSessions?.find(ts => ts.type)?.type || '';
  const playerTeamSessionTotals = getPlayersTeamGroupSessionTotals(
    playerSessions || [],
  );

  if (!playerSessions || !playerSessions.length || !playerTeamSessionTotals) {
    return <></>;
  }
  const segmentedSpeedAvgData =
    playerSessions && playerSessions.map(ps => ps.speedAvgData ?? []);
  const segmentedAccelAvgData =
    playerSessions && playerSessions.map(ps => ps.accelAvgData ?? []);
  const segmentedStrideRateAvgData =
    playerSessions && playerSessions.map(ps => ps.strideRateAvgData ?? []);
  const segmentedBalanceAvgData =
    playerSessions && playerSessions.map(ps => ps.balanceAvgData ?? []);
  const segmentedHeartRateData =
    playerSessions && playerSessions.map(ps => ps.heartRateData ?? []);

  const sessionStartAndEndTimes = playerSessions?.map(ps => ({
    startTime: ps.startTime || '',
    endTime: ps.endTime || '',
  }));

  const flattenedShifts = playerSessions.flatMap(
    ps =>
      ps.playerSessionShifts?.filter(
        x => x.valid || (showShiftReview && x.canUpdateValid),
      ) || [],
  );

  const shiftClips: PlayerSessionShift[] =
    playerSessions.flatMap(ps =>
      (ps.playerSessionShifts || [])?.filter(
        shift =>
          shift.valid && (shift.playerSessionVideoClips ?? []).length > 0,
      ),
    ) ?? [];

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <PageTitle>{t('pages.playerSessionGroupDetail')}</PageTitle>
        <BackButton navigationUrl={backPath}>{backText}</BackButton>
        <Box>
          <SubtitleContainer>
            <PageSubtitle sx={{flexGrow: 1, mr: 2}}>
              <StyledSessionType>
                {(type ?? '').toUpperCase()}
              </StyledSessionType>
              {DateTime.fromJSDate(playerTeamSessionTotals.startTime).toFormat(
                'EEEE M/d/yyyy h:mm a',
              )}
            </PageSubtitle>
          </SubtitleContainer>
        </Box>
        {shiftClips.length > 0 && (
          <SessionVideoPlayer shiftClips={shiftClips} />
        )}
        <Box>
          <ShiftTitleWrapper>
            <PageSubtitle>{t('shifts.title')}</PageSubtitle>
            <Box>
              <Button
                variant="outlined"
                onClick={() => setShowShiftReview(!showShiftReview)}>
                {t('shifts.shiftReview')}
              </Button>
            </Box>
          </ShiftTitleWrapper>
          <PlayerSessionShifts
            playerSessionIds={playerSessions?.map(ps => ps.id || 0) || []}
            shifts={flattenedShifts}
            inReview={showShiftReview}
            setInReview={setShowShiftReview}
            isProcessingVideo={
              !!playerSessions?.some(ps => ps.videoProcessingStartedAt)
            }
            sessionVideoIds={playerSessions
              .map(ps => {
                if (
                  ps &&
                  ps.sessionVideos &&
                  ps.sessionVideos.length > 0 &&
                  ps.sessionVideos[0].sessionVideoSegments &&
                  ps.sessionVideos[0].sessionVideoSegments?.length > 0
                ) {
                  return ps.sessionVideos[0]?.id ?? 0;
                }
                return 0;
              })
              .filter(i => i)}
          />
        </Box>
        <Box>
          <Paper sx={{mt: 5, mb: 5}}>
            <Grid container spacing={2}>
              <SessionGridItem
                label="Strides"
                value={
                  playerTeamSessionTotals.strideCountLeft +
                  playerTeamSessionTotals.strideCountRight
                }
              />
              <SessionGridItem
                label="Active Time"
                value={getTime(playerTeamSessionTotals.activeTime) ?? '--'}
              />
              <SessionGridItem
                label="Stride Time"
                value={getTime(playerTeamSessionTotals.strintTotalTime) ?? '--'}
              />
              <SessionGridItem
                label="Agility (Avg)"
                value={
                  playerTeamSessionTotals.strintFilteredStrideRateAvg
                    ? playerTeamSessionTotals.strintFilteredStrideRateAvg.toFixed(
                        0,
                      )
                    : '--'
                }
                unit={'spm'}
              />
              <SessionGridItem
                label="Agility (Max)"
                value={
                  playerTeamSessionTotals.strintFilteredStrideRateMax
                    ? playerTeamSessionTotals.strintFilteredStrideRateMax.toFixed(
                        0,
                      )
                    : '--'
                }
                unit={'spm'}
              />
              <SessionGridItem
                label="Points"
                value={playerTeamSessionTotals.points ?? '--'}
              />
              <SessionGridItem
                label="Hustle"
                value={
                  playerTeamSessionTotals.hustleScore
                    ? getIntensityText(
                        playerTeamSessionTotals.hustleScore,
                      ).toUpperCase()
                    : '--'
                }
              />
              <SessionGridItem
                label="Stride Speed (Avg)"
                value={
                  playerTeamSessionTotals.speedAvg
                    ? (
                        playerTeamSessionTotals.speedAvg * speedMultiplier
                      ).toFixed(1)
                    : '--'
                }
                unit={speedUnit}
              />
              <SessionGridItem
                label="Stride Speed (Max)"
                value={
                  playerTeamSessionTotals.speedMax
                    ? (
                        playerTeamSessionTotals.speedMax * speedMultiplier
                      ).toFixed(1)
                    : '--'
                }
                unit={speedUnit}
              />
              <SessionGridItem
                label="Explosiveness (Avg)"
                value={
                  playerTeamSessionTotals.accelAvg
                    ? (playerTeamSessionTotals.accelAvg / 9.8).toFixed(2)
                    : '--'
                }
                unit="g"
              />
              <SessionGridItem
                label="Explosiveness (Max)"
                value={
                  playerTeamSessionTotals.accelMax
                    ? (playerTeamSessionTotals.accelMax / 9.8).toFixed(2)
                    : '--'
                }
                unit="g"
              />
              <SessionGridItem
                label="Balance (%L / %R)"
                value={
                  playerTeamSessionTotals.balance
                    ? `${Math.round(playerTeamSessionTotals.balance * 100)} / ${Math.round((1 - playerTeamSessionTotals.balance) * 100)}`
                    : '--'
                }
              />
              {/* TODO, this item should show lock if user is not elite or pro */}
              <SessionGridItem
                label="Heart Rate (Avg)"
                value={playerTeamSessionTotals.hrAvg ?? '--'}
                unit="bpm"
              />
            </Grid>
            {segmentedSpeedAvgData?.length &&
            segmentedSpeedAvgData.some(shrd => shrd.length) ? (
              <Box sx={{m: 5}}>
                <Typography variant="h4">
                  {t('statistics.strideSpeedDetail')}
                </Typography>
                <TeamSessionGroupLineChart
                  data={segmentedSpeedAvgData}
                  metric={` ${speedUnit}`}
                  sessionTimes={sessionStartAndEndTimes || []}
                />
              </Box>
            ) : null}
            {segmentedAccelAvgData?.length &&
            segmentedAccelAvgData.some(shrd => shrd.length) ? (
              <Box sx={{m: 5}}>
                <Typography variant="h4">
                  {t('statistics.explosivenessDetail')}
                </Typography>
                <TeamSessionGroupLineChart
                  data={segmentedAccelAvgData}
                  metric={' g'}
                  sessionTimes={sessionStartAndEndTimes || []}
                />
              </Box>
            ) : null}
            {segmentedStrideRateAvgData?.length &&
            segmentedStrideRateAvgData.some(shrd => shrd.length) ? (
              <Box sx={{m: 5}}>
                <Typography variant="h4">
                  {t('statistics.strideRateDetail')}
                </Typography>
                <TeamSessionGroupLineChart
                  data={segmentedStrideRateAvgData}
                  metric={' spm'}
                  sessionTimes={sessionStartAndEndTimes || []}
                />
              </Box>
            ) : null}
            {segmentedBalanceAvgData?.length &&
            segmentedBalanceAvgData.some(shrd => shrd.length) ? (
              <Box sx={{m: 5}}>
                <Typography variant="h4">
                  {t('statistics.balanceDetail')}
                </Typography>
                <Box>
                  <SizeMe>
                    {({size}) => (
                      <TeamSessionGroupLineChart
                        data={segmentedBalanceAvgData}
                        sessionTimes={sessionStartAndEndTimes || []}
                        yAxisOverride={
                          <VictoryAxis
                            dependentAxis
                            tickValues={[0.05, 0.5, 0.95]}
                            tickFormat={tick => {
                              if (tick === 0.05) {
                                return 'Right';
                              } else if (tick === 0.5) {
                                return 'Balanced';
                              } else if (tick === 0.95) {
                                return 'Left';
                              }
                              return '';
                            }}
                            orientation="right"
                            style={yAxisStyle}
                            offsetX={size.width ?? 1120}
                          />
                        }
                        metric={''}
                      />
                    )}
                  </SizeMe>
                </Box>
              </Box>
            ) : null}
            {segmentedHeartRateData?.length &&
            segmentedHeartRateData.some(shrd => shrd.length) ? (
              <Box sx={{m: 5}}>
                <Typography variant="h4">
                  {t('statistics.hrAvgDetail')}
                </Typography>
                <TeamSessionGroupLineChart
                  data={segmentedHeartRateData}
                  sessionTimes={sessionStartAndEndTimes || []}
                  metric={' bpm'}
                />
              </Box>
            ) : null}
          </Paper>
        </Box>
        <PageSubtitle>{t('pages.playerSessions')}</PageSubtitle>
        <TableContainer sx={{mt: 3, mb: 5}} component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {(playerSessions || []).map(ps => (
                <SessionListItem
                  key={`sessionListItem-${ps.id}`}
                  teamId={teamId || 0}
                  profileId={playerView && profile.profile.id}
                  familyId={
                    family.families.length
                      ? family.families.find(f => f.id).id
                      : 0
                  }
                  session={ps}
                  sessionTag={getPlayerSessionGroupTag(ps, teamSessions || [])}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default PlayerSessionGroupDetail;
