import {Box, Button, styled, TableCell, TableRow} from '@mui/material';
import React from 'react';
import SessionInfo from '../shared/SessionInfo';
import styles from '../../styles';
import SessionTileStat from '../shared/SessionTileStat';
import {getTime} from '../../services/dateService';
import {useTranslation} from 'react-i18next';
import {PlayerSession} from '../../interfaces';
import {NavLink, useLocation} from 'react-router-dom';
import {getIntensityText} from '../../services/playerSessionService';

const TableCellWrapper = styled(TableCell)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    display: 'block',
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'table-cell',
  },
}));

interface Props {
  session: PlayerSession;
  teamId: number;
  profileId?: number;
  familyId?: number;
  sessionTag?: string;
}

const SessionListItem: React.FC<Props> = ({
  session,
  teamId,
  profileId,
  familyId,
  sessionTag,
}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const hash = location.hash;
  const {
    id,
    type,
    startTime,
    endTime,
    activeTime,
    hustleScore,
    points,
    strideCountLeft,
    strideCountRight,
    teamSessionId,
  } = session;
  const strideCount = (strideCountLeft || 0) + (strideCountRight || 0);
  let navigateUrl = `/teams/${teamId}/teamSessions/${teamSessionId}/playersessions/${id}${hash}`;
  if (familyId && profileId) {
    navigateUrl = `/family/children/${profileId}/playersessions/${id}`;
  } else if (profileId) {
    navigateUrl = `/teams/${teamId}/players/${profileId}/playersessions/${id}`;
  }
  const typeText = `${type || ''}${type && sessionTag ? ' - ' : ''}${sessionTag || ''}`;
  return (
    <TableRow
      sx={{
        borderBottom: {xs: '1px solid rgb(81, 81, 81)', md: 'none'},
      }}>
      <TableCellWrapper>
        <SessionInfo
          sxTitleAndLengthBox={{
            display: {xs: 'flex', md: 'block'},
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          type={typeText}
          startTime={startTime ? new Date(startTime) : undefined}
          endTime={endTime ? new Date(endTime) : undefined}
        />
      </TableCellWrapper>
      <TableCellWrapper>
        <Box sx={{...styles.flexDirectionRow}}>
          <SessionTileStat
            label="Active Time"
            value={getTime(activeTime) ?? '--'}
          />
          <SessionTileStat label="Strides" value={strideCount ?? '--'} />
          <SessionTileStat
            label="Hustle"
            value={
              hustleScore ? getIntensityText(hustleScore).toUpperCase() : '--'
            }
          />
          <SessionTileStat label="Points" value={points ?? '--'} />
        </Box>
      </TableCellWrapper>
      <TableCellWrapper align="right">
        <Button component={NavLink} to={navigateUrl}>
          {t('general.details')}
        </Button>
      </TableCellWrapper>
    </TableRow>
  );
};

export default SessionListItem;
