import React from 'react';
import {Grid} from '@mui/material';
import SessionTileStat from './SessionTileStat';

const SessionGridItem: React.FC<{
  label: string;
  value: any;
  unit?: string;
}> = ({label, value, unit}) => (
  <Grid item xs={4} lg={2}>
    <SessionTileStat label={label} value={value ?? '--'} unit={unit} />
  </Grid>
);

export default SessionGridItem;
