import {Paper, Table, TableBody, TableContainer} from '@mui/material';
import React from 'react';
import TeamSessionListItem from './TeamSessionListItem';
import {cloneDeep} from 'lodash';

interface Props {
  teamSessions: any[];
  teamSize?: number;
  isTeamSessionGroup?: boolean;
}

const TeamSessionsList: React.FC<Props> = ({
  teamSessions,
  teamSize,
  isTeamSessionGroup,
}) => {
  let combinedTeamSessions: any[] = [];

  if (!isTeamSessionGroup) {
    const teamSessionGroupsObject = teamSessions
      .filter(teamSession => teamSession.teamSessionGroup)
      .reduce((acc, teamSession) => {
        const key = teamSession.teamSessionGroup.id.toString();
        (acc[key] = acc[key] || []).push(teamSession);
        return acc;
      }, {});

    const teamSessionGroups = Object.keys(teamSessionGroupsObject).map(
      teamSessionGroupKey => {
        const teamSessionGroup = teamSessionGroupsObject[teamSessionGroupKey];

        const orderedTeamSessions = teamSessionGroup.sort((a: any, b: any) => {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return (
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
          );
        });

        const playerIds = orderedTeamSessions
          .map((teamSession: any) =>
            teamSession.playerSessions.map(
              (playerSession: any) => playerSession?.player?.id,
            ),
          )
          .reduce((a: any, b: any) => a.concat(b), []);

        const uniquePlayerIds = [...new Set(playerIds)];

        // return a new team session
        return {
          startTime: orderedTeamSessions[0].startTime,
          endTime: orderedTeamSessions.reverse()[0].endTime,
          type: orderedTeamSessions[0].type,
          participation: uniquePlayerIds.length,
          teamId: orderedTeamSessions[0].teamId,
          teamSize,
          teamSessionGroupId: orderedTeamSessions[0].teamSessionGroup.id,
        };
      },
    );

    const teamSessionIndividuals = cloneDeep(teamSessions)
      .filter(teamSession => !teamSession.teamSessionGroup)
      .map(teamSession => {
        teamSession.participation = (teamSession.playerSessions ?? []).length;
        return teamSession;
      });

    combinedTeamSessions = [...teamSessionGroups, ...teamSessionIndividuals];
  } else {
    combinedTeamSessions = cloneDeep(teamSessions).map(teamSession => {
      teamSession.participation = (teamSession.playerSessions ?? []).length;
      return teamSession;
    });
  }

  return (
    <TableContainer sx={{mb: 1}} component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {[...(combinedTeamSessions || [])]
            .sort((a: any, b: any) => {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              if (isTeamSessionGroup) {
                return (
                  new Date(a.startTime).getTime() -
                  new Date(b.startTime).getTime()
                );
              } else {
                return (
                  new Date(b.startTime).getTime() -
                  new Date(a.startTime).getTime()
                );
              }
            })
            .map((teamSession: any) => (
              <TeamSessionListItem
                key={
                  teamSession.teamSessionGroupId && !isTeamSessionGroup
                    ? `teamSessionGroup-${teamSession.teamSessionGroupId}`
                    : teamSession.id
                }
                startTime={new Date(teamSession.startTime)}
                endTime={new Date(teamSession.endTime)}
                type={teamSession.type}
                participation={teamSession.participation}
                sessionTags={teamSession.sessionTags}
                teamSize={teamSize}
                detailLink={
                  teamSession.teamSessionGroupId && !isTeamSessionGroup
                    ? `/teams/${teamSession.teamId}/teamsessiongroups/${teamSession.teamSessionGroupId}`
                    : `/teams/${teamSession.teamId}/teamsessions/${teamSession.id}`
                }
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamSessionsList;
