import React, {useMemo, useState} from 'react';
import {
  Box,
  MenuItem,
  Select,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {Baseline, PlayerSession} from '../../../interfaces';
import {
  getSpeedMultiplier,
  getSpeedUnit,
} from '../../../services/measurementService';
import TotalEffortLineChart from './TotalEffortLineChart';
import {DateTime} from 'luxon';
import {
  getBaselinesInDateRange,
  getSessionsInDateRange,
  isAllPlayerSessions,
  isPlayerSession,
} from '../../../services/playerSessionService';
import {setDecimalPlaces} from '../../../services/mathService';
import {GRAPH_YELLOW, ORANGE_COLOR} from '../../../styles';
import {useTranslation} from 'react-i18next';

const ChartLineExample = styled(Box)(() => ({
  borderWidth: 2,
  borderTopStyle: 'solid',
  minWidth: 50,
  marginLeft: 5,
}));

const ChartExampleContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 10,
  marginRight: 10,
}));

type BaselineKey = keyof Baseline;
type PlayerSessionKey = keyof (PlayerSession & {strideCount: number});

interface DataValue {
  value: BaselineKey | PlayerSessionKey;
  label: string;
}

const dataValueOptions: DataValue[] = [
  {
    value: 'speedMax',
    label: 'Stride Speed',
  },
  {
    value: 'accelMax',
    label: 'Explosiveness',
  },
  {
    value: 'strintFilteredStrideRateMax',
    label: 'Agility',
  },
  {
    value: 'balanceNormalized',
    label: 'Balance',
  },
  {
    value: 'strideCount',
    label: 'Strides',
  },
  {
    value: 'activeTime',
    label: 'Active Time',
  },
  {
    value: 'hrMax',
    label: 'Heart Rate',
  },
];

const playerSessionDataSources = ['strideCount', 'activeTime'];

const getSessionInDay = (session: PlayerSession[], dayNumber: number) => {
  const dataInDay = session.filter((playerSession: PlayerSession) => {
    const relevantDate = playerSession.startTime;
    if (!relevantDate) {
      return false;
    }
    const endOfCurrentDay = DateTime.local().endOf('day');
    const endOfSessionDay = DateTime.fromISO(relevantDate?.toString()).endOf(
      'day',
    );
    // Make sure to return only the correct days data
    const dateDiff = endOfSessionDay.diff(endOfCurrentDay, 'days').days;
    return dateDiff * -1 === dayNumber;
  });
  return dataInDay;
};

const getBaselineInDay = (baseline: Baseline[], dayNumber: number) => {
  const dataInDay = baseline.filter((baseline: Baseline) => {
    const relevantDate = baseline.lastSessionDate;
    if (!relevantDate) {
      return false;
    }
    const endOfCurrentDay = DateTime.local().endOf('day');
    const endOfSessionDay = DateTime.fromISO(relevantDate?.toString()).endOf(
      'day',
    );
    // Make sure to return only the correct days data
    const dateDiff = endOfSessionDay.diff(endOfCurrentDay, 'days').days;
    return dateDiff * -1 === dayNumber;
  });

  return dataInDay;
};

const getRawValue = ({
  ps,
  dataValue,
}: {
  ps: Baseline | PlayerSession | number;
  dataValue: DataValue;
}): number => {
  if (typeof ps === 'number') {
    return ps;
  }
  switch (dataValue.value) {
    case 'strideCount':
      if (!ps.strideCountRight || !ps.strideCountLeft) {
        return 0;
      }
      return ps.strideCountLeft + ps.strideCountRight;
    case 'speedMax':
      return ps.speedMax || 0;
    case 'accelMax':
      return ps.accelMax || 0;
    case 'activeTime':
      return ps.activeTime || 0;
    case 'strintFilteredStrideRateMax':
      return isPlayerSession(ps) ? 0 : ps.strintFilteredStrideRateMax || 0;
    case 'balanceNormalized':
      return ps.balanceNormalized || 0;
    case 'hrMax':
      return ps.hrMax || 0;
    default:
      return 0;
  }
};

const getGraphValue = ({
  ps,
  dataValue,
  speedMultiplier,
}: {
  ps: Baseline | PlayerSession | number;
  dataValue: DataValue;
  speedMultiplier?: number;
}): number => {
  switch (dataValue.value) {
    case 'strideCount':
      if (typeof ps === 'number') {
        return ps;
      }
      if (!ps.strideCountRight || !ps.strideCountLeft) {
        return 0;
      }
      return ps.strideCountLeft + ps.strideCountRight;
    case 'speedMax':
      return setDecimalPlaces(
        (typeof ps === 'number' ? ps : ps.speedMax || 0) *
          (speedMultiplier ?? 1),
        1,
      );
    case 'accelMax':
      return setDecimalPlaces(
        (typeof ps === 'number' ? ps : ps.accelMax || 0) / 9.8,
        2,
      );
    case 'activeTime':
      return (typeof ps === 'number' ? ps : ps.activeTime || 0) / 60;
    case 'strintFilteredStrideRateMax':
      return isPlayerSession(ps)
        ? 0
        : typeof ps === 'number'
          ? ps
          : ps.strintFilteredStrideRateMax || 0;
    case 'balanceNormalized':
      return typeof ps === 'number' ? ps : ps.balanceNormalized || 0;
    case 'hrMax':
      return typeof ps === 'number' ? ps : ps.hrMax || 0;
    default:
      return 0;
  }
};

const getDayAverages = (
  dataArray: Baseline[] | PlayerSession[],
  daysAgo: number,
  dataValue: DataValue,
  speedMultiplier: number,
) => {
  const dataInRange = isAllPlayerSessions(dataArray)
    ? getSessionsInDateRange(dataArray, daysAgo)
    : getBaselinesInDateRange(dataArray, daysAgo);
  const dailyData = [...Array(daysAgo).keys()].map((_, dayNumber) => {
    const dataInDay = isAllPlayerSessions(dataInRange)
      ? getSessionInDay(dataInRange, dayNumber)
      : getBaselineInDay(dataInRange, dayNumber);
    // If no data in the day, return 0
    if (!dataInDay.length) {
      return 0;
    }
    // add all of the values for the day
    const dayTotal = isAllPlayerSessions(dataInDay)
      ? dataInDay.reduce((acc: number, currPlayerSession: PlayerSession) => {
          const rawValue = getRawValue({
            ps: currPlayerSession,
            dataValue,
          });
          return acc + (rawValue || 0);
        }, 0)
      : dataInDay.reduce((acc: number, currPlayerSession: Baseline) => {
          const rawValue = getRawValue({ps: currPlayerSession, dataValue});
          return acc + (rawValue || 0);
        }, 0);
    // return the average for the day
    return dayTotal / dataInDay.length;
  });
  return dailyData.map(d => getGraphValue({ps: d, dataValue, speedMultiplier}));
};

interface Props {
  baselines: Baseline[];
  playerSessions: PlayerSession[];
  preferredUnits: 'metric' | 'imperial';
}

const PlayerPlotChart: React.FC<Props> = ({
  baselines,
  playerSessions,
  preferredUnits,
}) => {
  const [dataValue, setDataValue] = useState(dataValueOptions[0]);
  const [timeValue, setTimeValue] = useState('allTime');
  const {t} = useTranslation();

  const speedMultiplier = getSpeedMultiplier(preferredUnits === 'metric');

  const gameData: number[] = useMemo(() => {
    const isPlayerSessionSelected = playerSessionDataSources.includes(
      dataValue.value,
    );
    const gameSessions = isPlayerSessionSelected
      ? playerSessions.slice().filter(ps => ps.type === 'game')
      : baselines.slice().filter(bs => bs.type === 'game');
    if (!gameSessions?.length) {
      return [];
    }
    if (timeValue === 'allTime') {
      const returnData = gameSessions
        .sort((a, b) => {
          const aVal = isPlayerSession(a) ? a.startTime : a.lastSessionDate;
          const bVal = isPlayerSession(b) ? b.startTime : b.lastSessionDate;
          return DateTime.fromISO(aVal?.toString() || '').valueOf >
            DateTime.fromISO(bVal?.toString() || '').valueOf
            ? 1
            : -1;
        })
        .map(ps => getGraphValue({ps, dataValue, speedMultiplier}));
      return returnData;
    }
    const filterDays = timeValue === 'week' ? 7 : DateTime.local().day;
    const dailyAverages = getDayAverages(
      gameSessions,
      filterDays,
      dataValue,
      speedMultiplier,
    );
    return dailyAverages;
  }, [dataValue, playerSessions, baselines, timeValue, speedMultiplier]);

  const practiceData: number[] = useMemo(() => {
    const isPlayerSessionSelected = playerSessionDataSources.includes(
      dataValue.value,
    );
    const practiceSessions = isPlayerSessionSelected
      ? playerSessions.slice().filter(ps => ps.type === 'practice')
      : baselines.slice().filter(bs => bs.type === 'practice');
    if (!practiceSessions?.length) {
      return [];
    }
    if (timeValue === 'allTime') {
      const returnData = practiceSessions
        .sort((a, b) => {
          const aVal = isPlayerSession(a) ? a.startTime : a.lastSessionDate;
          const bVal = isPlayerSession(b) ? b.startTime : b.lastSessionDate;
          return DateTime.fromISO(aVal?.toString() || '').valueOf >
            DateTime.fromISO(bVal?.toString() || '').valueOf
            ? 1
            : -1;
        })
        .map(ps => getGraphValue({ps, dataValue, speedMultiplier}));
      return returnData;
    }
    const filterDays = timeValue === 'week' ? 7 : DateTime.local().day;
    const dailyAverages = getDayAverages(
      practiceSessions,
      filterDays,
      dataValue,
      speedMultiplier,
    );
    return dailyAverages;
  }, [dataValue, playerSessions, baselines, timeValue, speedMultiplier]);

  const allSessionData: number[] = useMemo(() => {
    // activeTime and strideCount use playerSessions
    const isPlayerSessionSelected = playerSessionDataSources.includes(
      dataValue.value,
    );
    const dataSource = isPlayerSessionSelected ? playerSessions : baselines;
    if (!dataSource?.length) {
      return [];
    }
    if (timeValue === 'allTime') {
      const returnData = dataSource
        .slice()
        .sort((a, b) => {
          const aVal = isPlayerSession(a) ? a.startTime : a.lastSessionDate;
          const bVal = isPlayerSession(b) ? b.startTime : b.lastSessionDate;
          return DateTime.fromISO(aVal?.toString() || '').valueOf >
            DateTime.fromISO(bVal?.toString() || '').valueOf
            ? 1
            : -1;
        })
        .map(ps => getGraphValue({ps, dataValue, speedMultiplier}));
      return returnData;
    }
    const filterDays = timeValue === 'week' ? 7 : DateTime.local().day;
    const dailyAverages = getDayAverages(
      dataSource,
      filterDays,
      dataValue,
      speedMultiplier,
    );
    return dailyAverages;
  }, [dataValue, playerSessions, baselines, timeValue, speedMultiplier]);

  const getDecimals = () => {
    switch (dataValue.value) {
      case 'accelMax':
        return 2;
      case 'speedMax':
        return 1;
      default:
        return 0;
    }
  };

  const metric = useMemo(() => {
    switch (dataValue.value) {
      case 'speedMax':
        return getSpeedUnit(preferredUnits === 'metric');
      case 'accelMax':
        return 'g';
      case 'strintFilteredStrideRateMax':
        return 'spm';
      case 'hrMax':
        return ' bpm';
      case 'activeTime':
        return ' min.';
      case 'strideCount':
      case 'balanceNormalized':
      default:
        return '';
    }
  }, [dataValue, preferredUnits]);

  return (
    <Box>
      <Typography sx={{color: ORANGE_COLOR, ml: 2}} variant="h5">
        {t('statistics.performanceAnalysis')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}>
        <Box sx={{flex: 0.25, margin: 1}}>
          <Select
            sx={{
              width: '100%',
              fontWeight: 700,
              fontSize: '1.2rem',
              fontStyle: 'italic',
            }}
            labelId="team-select-label"
            value={dataValue.value}
            onChange={v => {
              const newValue = dataValueOptions.find(
                dv => dv.value === v.target.value,
              );
              if (newValue) {
                setDataValue(newValue);
              }
            }}>
            {dataValueOptions.map(dv => (
              <MenuItem key={dv.value} value={dv.value}>
                {dv.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{margin: 1}}>
          <ToggleButtonGroup
            value={timeValue}
            onChange={(_, v) => setTimeValue(v)}
            exclusive
            size="large">
            <ToggleButton value="week" key="week">
              Week
            </ToggleButton>
            <ToggleButton value="month" key="month">
              Month
            </ToggleButton>
            <ToggleButton value="allTime" key="allTime">
              All Time
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Box>
        <TotalEffortLineChart
          gameData={gameData}
          practiceData={practiceData}
          allData={allSessionData}
          metric={metric}
          range={timeValue}
          decimals={getDecimals()}
          dataValue={dataValue.value}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <ChartExampleContainer>
          <Typography>Practice</Typography>
          <ChartLineExample
            sx={{
              borderColor: GRAPH_YELLOW,
            }}
          />
        </ChartExampleContainer>
        <ChartExampleContainer>
          <Typography>Game</Typography>
          <ChartLineExample
            sx={{
              borderColor: ORANGE_COLOR,
            }}
          />
        </ChartExampleContainer>
        <ChartExampleContainer>
          <Typography>Trend</Typography>
          <ChartLineExample
            sx={{
              borderColor: 'rgba(255,255,255,0.8)',
            }}
          />
        </ChartExampleContainer>
      </Box>
    </Box>
  );
};

export default PlayerPlotChart;
