import React from 'react';
import {Avatar, Box, Grid, styled, Typography} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import {useTranslation} from 'react-i18next';
import {Profile} from '../../../interfaces';
import {DateTime} from 'luxon';
import PlayerNumber from '../../shared/PlayerNumber';

const ProfileIdentityWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 16,
  marginRight: 16,
}));

const ProfilePictureImage = styled(Avatar)(() => ({
  height: '60px',
  width: '60px',
  marginRight: '10px',
  background: 'transparent',
  border: 'solid 1px #fff',
}));

const ProfileStatGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

interface Props {
  profileDetail: Profile;
}

const PlayerProfileRow: React.FC<Props> = ({profileDetail}) => {
  const {
    firstName,
    lastName,
    position,
    profilePictureFileKey,
    shot,
    height,
    weight,
    hometown,
    preferredUnits,
    dateOfBirth,
  } = profileDetail;
  const number = profileDetail?.players?.[0]?.number;
  const {t} = useTranslation();
  const weightUnit = preferredUnits === 'metric' ? 'kg' : 'lbs';
  const heightUnit = preferredUnits === 'metric' ? 'cm' : 'in';
  const birthday =
    dateOfBirth &&
    DateTime.fromJSDate(new Date(dateOfBirth)).toFormat('MM/dd/yyyy');
  const getAge = () => {
    if (!dateOfBirth) {
      return 'n/a';
    }
    const dateOfBirthDateObject = DateTime.fromJSDate(new Date(dateOfBirth));
    const now = DateTime.now();
    const age = now.diff(dateOfBirthDateObject, 'years').years;
    return Math.floor(age);
  };

  return (
    <ProfileIdentityWrapper>
      <Grid container sx={{justifyContent: 'space-between'}}>
        <Grid item xs={12} sx={{marginBottom: 2}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            {!!number && <PlayerNumber number={number} />}
            {profilePictureFileKey ? (
              <ProfilePictureImage
                src={profilePictureFileKey}
                alt={`${firstName} ${lastName}`}
              />
            ) : (
              <ProfilePictureImage>
                <PersonIcon color="info" sx={{fontSize: '40px'}} />
              </ProfilePictureImage>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <Typography variant="h3">
                <em>{`${firstName} ${lastName}`}</em>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <ProfileStatGrid item xs={6} lg={2} sx={{mb: 2}}>
          <Typography variant="h5">
            Position: {position ? t(`position.${position}`) : 'n/a'}
          </Typography>
          <Typography sx={{textTransform: 'capitalize'}} variant="h5">
            Shot: {shot || 'n/a'}
          </Typography>
        </ProfileStatGrid>
        <ProfileStatGrid item xs={6} lg={2} sx={{mb: 2}}>
          <Typography variant="h5">
            Height: {height ? `${height}${heightUnit}` : 'n/a'}
          </Typography>
          <Typography variant="h5">
            Weight: {weight ? `${weight}${weightUnit}` : 'n/a'}
          </Typography>
        </ProfileStatGrid>
        <ProfileStatGrid item xs={6} lg={2} sx={{mb: 2}}>
          <Typography variant="h5">Birthday: {birthday || 'n/a'}</Typography>
          <Typography variant="h5">Age: {getAge()}</Typography>
        </ProfileStatGrid>
        <ProfileStatGrid item xs={6} lg={2} sx={{mb: 2}}>
          <Typography variant="h5">Hometown: {hometown || 'n/a'}</Typography>
        </ProfileStatGrid>
      </Grid>
    </ProfileIdentityWrapper>
  );
};

export default PlayerProfileRow;
