import React from 'react';
import {Button, TableCell, TableRow} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import SessionInfo from './SessionInfo';
import SessionTileStat from './SessionTileStat';
import {SessionTag} from '../../interfaces';

interface Props {
  type: string;
  startTime: Date;
  endTime: Date;
  participation: number;
  teamSize?: number;
  detailLink: string;
  sessionTags?: SessionTag[];
}

const TeamSessionListItem: React.FC<Props> = ({
  type,
  startTime,
  endTime,
  participation,
  teamSize,
  detailLink,
  sessionTags,
}) => {
  const {t} = useTranslation();
  const sessionTag = sessionTags?.length ? sessionTags[0].name : '';
  const typeText = `${type || ''}${type && sessionTag ? ' - ' : ''}${sessionTag || ''}`;

  return (
    <TableRow>
      <TableCell>
        <SessionInfo type={typeText} startTime={startTime} endTime={endTime} />
      </TableCell>
      <TableCell>
        <SessionTileStat
          label="Participation"
          value={`${participation}${teamSize ? `/${teamSize}` : ''}`}
        />
      </TableCell>
      <TableCell align="right">
        <Button component={NavLink} to={detailLink}>
          {t('general.details')}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default TeamSessionListItem;
