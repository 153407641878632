import {TextField} from '@mui/material';
import React from 'react';
import {Control, Controller} from 'react-hook-form';

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
  required?: boolean;
}

const FormTextField: React.FC<Props> = ({control, name, label, required}) => (
  <Controller
    name={name}
    control={control}
    rules={{
      required: required ? `${label || 'This field'} is required` : undefined,
    }}
    render={({field, fieldState: {error}}) => (
      <TextField
        label={label}
        variant="outlined"
        sx={{mb: 2}}
        fullWidth
        error={!!error}
        helperText={error ? error.message : ''}
        {...field}
      />
    )}
  />
);

export default React.memo(FormTextField);
