import React, {useCallback, useMemo, useState} from 'react';
import {Alert, Box, FormControlLabel, Switch, Typography} from '@mui/material';
import ProgressBar from '../../shared/ProgressBar';
import styles, {ORANGE_COLOR} from '../../../styles';
import {useTranslation} from 'react-i18next';
import {Skills} from '../../../interfaces';
import {Genders, Positions} from '../../../enums';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

interface Props {
  skills?: Skills[];
  year?: string;
  profileGender?: string;
  profilePosition?: string;
}

const PlayerRankings: React.FC<Props> = ({
  skills,
  year,
  profilePosition,
  profileGender,
}) => {
  const {t} = useTranslation();

  const [filterByGender, setFilterByGender] = useState<boolean>(false);
  const [filterByPosition, setFilterByPosition] = useState<boolean>(false);

  const backendGender = useMemo(() => {
    if (!profileGender || profileGender === Genders.unknown) {
      return '';
    }
    return profileGender;
  }, [profileGender]);

  const backendPosition = useMemo(() => {
    if (!profilePosition || profilePosition === Positions.goalie) {
      return '';
    }
    if (
      [Positions.center, Positions.leftwing, Positions.rightwing].includes(
        profilePosition as Positions,
      )
    ) {
      return 'forward';
    }
    return profilePosition;
  }, [profilePosition]);

  const handleFilterByGenderSwitch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterByGender(e.target.checked);
    },
    [],
  );

  const handleFilterByPositionSwitch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterByPosition(e.target.checked);
    },
    [],
  );

  const skill = useMemo(
    () =>
      (skills ?? []).find(skill => {
        if (filterByGender && backendGender !== skill.gender) {
          return false;
        }
        if (filterByPosition && backendPosition !== skill.position) {
          return false;
        }
        return true;
      }),
    [backendGender, backendPosition, filterByGender, filterByPosition, skills],
  );

  const {
    strideSpeedScore,
    strideSpeedProgress,
    explosivenessScore,
    explosivenessProgress,
    agilityScore,
    agilityProgress,
    balanceScore,
    balanceProgress,
  } = skill ?? {};

  const positionSwitchLabel = useMemo(() => {
    return backendPosition !== ''
      ? t('statistics.filterByPosition', {
          position: backendPosition,
        })
      : t('statistics.filterByPositionEmpty');
  }, [backendPosition, t]);

  const genderSwitchLabel = useMemo(() => {
    return backendGender
      ? t('statistics.filterByGender', {gender: backendGender})
      : t('statistics.filterByGenderEmpty');
  }, [backendGender, t]);

  const skillInfoMessage = useMemo(() => {
    return !strideSpeedScore &&
      !explosivenessScore &&
      !agilityScore &&
      !balanceScore
      ? t('statistics.noSkills')
      : !strideSpeedScore ||
          !explosivenessScore ||
          !agilityScore ||
          !balanceScore
        ? t('statistics.someSkills')
        : null;
  }, [agilityScore, balanceScore, explosivenessScore, strideSpeedScore, t]);

  return (
    <Box sx={{mx: 2}}>
      <Typography sx={{color: ORANGE_COLOR, mb: 2}} variant="h5">
        {t('statistics.playerRankings')}
      </Typography>
      <Box sx={{mx: 5}}>
        <Typography variant="body1" sx={{mb: 2}}>
          {t('statistics.playerRankingsDescription', {year})}
        </Typography>
        {strideSpeedScore && strideSpeedProgress === 100 ? (
          <ProgressBar
            labelWidth={140}
            label={t('statistics.strideSpeedTitle') || ''}
            value={strideSpeedScore / 100}
            showValue={true}
          />
        ) : (
          <ProgressBar
            labelWidth={140}
            label={t('statistics.strideSpeedTitle') || ''}
            value={(strideSpeedProgress || 0) / 100}
            showLock={true}
            overrideColor="#ffffff"
          />
        )}
        {explosivenessScore && explosivenessProgress === 100 ? (
          <ProgressBar
            labelWidth={140}
            label={t('statistics.explosivenessTitle') || ''}
            value={explosivenessScore / 100}
            showValue={true}
          />
        ) : (
          <ProgressBar
            labelWidth={140}
            label={t('statistics.explosivenessTitle') || ''}
            value={(explosivenessProgress || 0) / 100}
            showLock={true}
            overrideColor="#ffffff"
          />
        )}
        {agilityScore && agilityProgress === 100 ? (
          <ProgressBar
            labelWidth={140}
            label={t('statistics.agilityTitle') || ''}
            value={agilityScore / 100}
            showValue={true}
          />
        ) : (
          <ProgressBar
            labelWidth={140}
            label={t('statistics.agilityTitle') || ''}
            value={(agilityProgress || 0) / 100}
            showLock={true}
            overrideColor="#ffffff"
          />
        )}
        {balanceScore && balanceProgress === 100 ? (
          <ProgressBar
            labelWidth={140}
            label={t('statistics.balanceTitle') || ''}
            value={balanceScore / 100}
            showValue={true}
          />
        ) : (
          <ProgressBar
            labelWidth={140}
            label={t('statistics.balanceTitle') || ''}
            value={(balanceProgress || 0) / 100}
            showLock={true}
            overrideColor="#ffffff"
          />
        )}
        <Box>
          <FormControlLabel
            sx={styles.textTransformCapitalize}
            value={filterByGender}
            disabled={backendGender === ''}
            control={
              <Switch
                inputProps={{
                  'aria-label': genderSwitchLabel ?? '',
                }}
                onChange={handleFilterByGenderSwitch}
              />
            }
            label={genderSwitchLabel}
            labelPlacement="end"
          />
          <FormControlLabel
            sx={styles.textTransformCapitalize}
            value={filterByPosition}
            disabled={backendPosition === ''}
            control={
              <Switch
                inputProps={{
                  'aria-label': positionSwitchLabel ?? '',
                }}
                onChange={handleFilterByPositionSwitch}
              />
            }
            label={positionSwitchLabel}
            labelPlacement="end"
          />
        </Box>
        {backendGender === '' && (
          <Alert
            sx={{mb: 2}}
            severity="info"
            icon={<LockOutlinedIcon fontSize="small" />}
            variant="outlined">
            {t('statistics.genderNotSelected')}
          </Alert>
        )}
        {backendGender !== '' &&
          (skills ?? []).length > 0 &&
          !skills?.find(s => s.gender === backendGender) && (
            <Alert
              sx={{mb: 2}}
              severity="info"
              icon={<LockOutlinedIcon fontSize="small" />}
              variant="outlined">
              {t('statistics.skillNotAvailableGender')}
            </Alert>
          )}
        {backendPosition === '' && (
          <Alert
            sx={{mb: 2}}
            severity="info"
            icon={<LockOutlinedIcon fontSize="small" />}
            variant="outlined">
            {t('statistics.positionNotSelected')}
          </Alert>
        )}
        {backendPosition !== '' &&
          (skills ?? []).length > 0 &&
          !skills?.find(s => s.position === backendPosition) && (
            <Alert
              sx={{mb: 2}}
              severity="info"
              icon={<LockOutlinedIcon fontSize="small" />}
              variant="outlined">
              {t('statistics.skillNotAvailablePosition')}
            </Alert>
          )}
        {skillInfoMessage && (
          <Alert
            sx={{mb: 2}}
            severity="info"
            icon={<LockOutlinedIcon fontSize="small" />}
            variant="outlined">
            {skillInfoMessage}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default PlayerRankings;
