import {Paper, Table, TableBody, TableContainer} from '@mui/material';
import React, {useMemo} from 'react';
import PlayerSessionListItem from './PlayerSessionListItem';
import {compare} from '../../helpers';
import {positions} from '../../services/teamService';
import {PlayerSession} from '../../interfaces';

interface Props {
  navigateUrlPrefix: string;
  teamSessionGroupId: number;
  playerSessions: PlayerSession[];
  showProfile?: boolean;
  showDate?: boolean;
  sortBy?: 'default' | 'teamPositionThenNameAsc';
}

const PlayersGroupSessionList: React.FC<Props> = ({
  navigateUrlPrefix,
  teamSessionGroupId,
  playerSessions,
  showProfile,
  showDate,
  sortBy,
}) => {
  const groupedPlayerSessions: PlayerSession[] = useMemo(() => {
    const groupedPS: PlayerSession[] = [];
    if (playerSessions.length) {
      playerSessions.slice().map(ps => {
        const playerId = ps.playerId;
        if (!playerId || groupedPS.find(gps => gps.playerId === playerId)) {
          return null;
        }
        const allGroupedSessions = playerSessions.filter(
          ps => ps.playerId === playerId,
        );
        if (allGroupedSessions.length <= 1) {
          return null;
        }
        // sum new session values
        const totalHustlePoints = allGroupedSessions.reduce((curr, acc) => {
          return curr + (acc.hustleScore || 0);
        }, 0);
        const newSessionValues = {
          activeTime: allGroupedSessions.reduce((curr, acc) => {
            return curr + (acc.activeTime || 0);
          }, 0),
          strideCountLeft: allGroupedSessions.reduce((curr, acc) => {
            return curr + (acc.strideCountLeft || 0);
          }, 0),
          strideCountRight: allGroupedSessions.reduce((curr, acc) => {
            return curr + (acc.strideCountRight || 0);
          }, 0),
          hustleScore: parseInt(
            (totalHustlePoints / allGroupedSessions.length).toFixed(),
          ),
          points: allGroupedSessions.reduce((curr, acc) => {
            return curr + (acc.points || 0);
          }, 0),
          startTime: allGroupedSessions
            .slice()
            .sort(
              (a, b) =>
                new Date(a.startTime || '').valueOf() -
                new Date(b.startTime || '').valueOf(),
            )[0].startTime,
          endTime: allGroupedSessions
            .slice()
            .sort(
              (a, b) =>
                new Date(b.endTime || '').valueOf() -
                new Date(a.endTime || '').valueOf(),
            )[0].endTime,
          hasPlayerData:
            allGroupedSessions.slice().filter(ps => ps.hasPlayerData).length >
            0,
        };
        return groupedPS.push({...ps, ...newSessionValues});
      });
    }
    return groupedPS;
  }, [playerSessions]);

  const teamHasNumbers = useMemo(
    () => (playerSessions ?? []).some(ps => (ps.player?.number ?? 0) > 0),
    [playerSessions],
  );

  return (
    <TableContainer sx={{mt: 3, mb: 5}} component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {groupedPlayerSessions
            .sort((a: PlayerSession, b) => {
              if (sortBy === 'teamPositionThenNameAsc') {
                return (
                  compare(
                    positions.indexOf(a.player?.profile?.position || ''),
                    positions.indexOf(b.player?.profile?.position || ''),
                  ) ||
                  compare(
                    a.player?.profile?.lastName,
                    b.player?.profile?.lastName,
                  )
                );
              } else {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return (
                  new Date(b.startTime || '').getTime() -
                  new Date(a.startTime || '').getTime()
                );
              }
            })
            .map((playerSession: PlayerSession) => (
              <PlayerSessionListItem
                key={playerSession.id}
                navigateUrl={`${navigateUrlPrefix}${playerSession.player?.profile?.id}/playersessions/groups/${teamSessionGroupId}#tsg`}
                profileInfo={
                  showProfile
                    ? {
                        firstName:
                          playerSession.player?.profile?.firstName || '',
                        lastName: playerSession.player?.profile?.lastName || '',
                        position: playerSession.player?.profile?.position || '',
                        profilePictureUrl:
                          playerSession.player?.profile?.profilePictureFileKey,
                      }
                    : undefined
                }
                teamHasNumbers={teamHasNumbers}
                number={playerSession.player?.number}
                type={playerSession.type || ''}
                hasPlayerData={!!playerSession.hasPlayerData}
                startTime={
                  showDate && playerSession.startTime
                    ? new Date(playerSession.startTime)
                    : undefined
                }
                endTime={
                  showDate && playerSession.endTime
                    ? new Date(playerSession.endTime)
                    : undefined
                }
                activeTime={playerSession.activeTime}
                strideCount={
                  (playerSession.strideCountLeft || 0) +
                  (playerSession.strideCountRight || 0)
                }
                hustleScore={playerSession.hustleScore}
                points={playerSession.points}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlayersGroupSessionList;
