import React, {useEffect, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import {Box, Container, Divider, styled} from '@mui/material';
import PageTitle from '../shared/PageTitle';
import {useParams} from 'react-router-dom';
import {BORDER_COLOR, CARD_BORDER_RADIUS, GRAY_BG_COLOR} from '../../styles';
import PlayerProfileRow from './components/PlayerProfileRow';
import {getProfileDetailAsync} from '../../store/profileSlice';
import FullScreenLoadingSpinner from '../shared/FullScreenLoading';
import PlayerLoadRow from './components/PlayerLoadRow';
import ShiftMetricsRow from './components/ShiftMetricsRow';
import {getPlayerSessionsAsync} from '../../store/playerSessionSlice';
import {getTime} from '../../services/dateService';
import PlayerPlotChart from './components/PlayerPlotChart';
import {getBaselinesAsync} from '../../store/baselineSlice';
import PlayerRankings from './components/PlayerRankings';
import {Baseline} from '../../interfaces';

const DashboardWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: GRAY_BG_COLOR,
  padding: 16,
  borderRadius: CARD_BORDER_RADIUS,
}));

const StyledDivider = styled(Divider)(() => ({
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: BORDER_COLOR,
  marginTop: 10,
  marginBottom: 10,
}));

const PlayerDashboard: React.FC = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {profileDetail, playerSessions, baselines, acwrScores} = useAppSelector(
    state => ({
      profileDetail: state.profile.profileDetail,
      playerSessions: state.playerSession?.playerSessions,
      baselines: state.baseline?.baselines,
      acwrScores: state.profile.profileDetail?.acwrScores,
    }),
  );
  const {profileId} = useParams();

  const {
    // avgShiftCount,
    // avgTOI,
    avgShiftTime,
    energyRatio,
  } = useMemo(() => {
    const gameBaselines = baselines.filter(bs => bs.type === 'game');
    const mostRecentBaseline = [
      ...gameBaselines.sort(
        (a: Baseline, b: Baseline) =>
          new Date(b.lastSessionDate as string).getTime() -
          new Date(a.lastSessionDate as string).getTime(),
      ),
    ]?.[0];

    // const avgShiftCount = getAvgShiftCount(gameBaselines || []);
    // const avgTOI = getAvgTimeOnIce(gameBaselines || []);
    const avgShiftTime = mostRecentBaseline?.shiftTimeAvg ?? 0;
    const energyRatio = mostRecentBaseline?.energyRatio ?? 0;
    const rankingValues = () => {
      const relevantSkills = profileDetail?.skills?.find(
        s => s.gender && s.position,
      );
      return {
        strideSpeedScore: relevantSkills?.strideSpeedScore,
        strideSpeedProgress: relevantSkills?.strideSpeedProgress,
        explosivenessScore: relevantSkills?.explosivenessScore,
        explosivenessProgress: relevantSkills?.explosivenessProgress,
        agilityScore: relevantSkills?.agilityScore,
        agilityProgress: relevantSkills?.agilityProgress,
        balanceScore: relevantSkills?.balanceScore,
        balanceProgress: relevantSkills?.balanceProgress,
      };
    };
    return {
      // avgShiftCount,
      // avgTOI: getTime(avgTOI) || '0:00',
      avgShiftTime: getTime(avgShiftTime) || '0:00',
      energyRatio,
      rankingValues: rankingValues(),
    };
  }, [baselines, profileDetail?.skills]);

  useEffect(() => {
    if (profileId) {
      dispatch(getProfileDetailAsync({profileId: parseInt(profileId)}));
      dispatch(getPlayerSessionsAsync({profileId: parseInt(profileId)}));
      dispatch(
        getBaselinesAsync({
          profileId: parseInt(profileId),
          types: ['game', 'practice'],
        }),
      );
    }
  }, [dispatch, profileId]);

  if (!profileDetail) {
    return (
      <>
        <Helmet>
          <title>
            {t('navigation.pageTitle', {pageName: t('pages.playerDashboard')})}
          </title>
        </Helmet>
        <FullScreenLoadingSpinner />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {t('navigation.pageTitle', {pageName: t('pages.playerDashboard')})}
        </title>
      </Helmet>
      <Container>
        <PageTitle>{t('pages.playerDashboard')}</PageTitle>
        <DashboardWrapper>
          <PlayerProfileRow profileDetail={profileDetail} />
          <StyledDivider />
          <PlayerLoadRow acwrScores={acwrScores ?? []} />
          <StyledDivider />
          <ShiftMetricsRow
            avgShiftTime={avgShiftTime}
            // avgTOI={avgTOI}
            // avgShiftCount={avgShiftCount}
            avgEnergyRatio={energyRatio}
          />
          <StyledDivider />
          <PlayerRankings
            skills={profileDetail?.skills ?? []}
            year={(profileDetail?.dateOfBirth || '').split('-')[0]}
            profileGender={profileDetail?.gender}
            profilePosition={profileDetail?.position}
          />
          <StyledDivider />
          <PlayerPlotChart
            baselines={baselines || []}
            playerSessions={playerSessions || []}
            preferredUnits={profileDetail.preferredUnits || 'imperial'}
          />
        </DashboardWrapper>
      </Container>
    </>
  );
};

export default PlayerDashboard;
