import apiGraphQL from './apiGraphQL';
import {AxiosPromise} from 'axios';

export function getBaselines(profileId: number, types: string[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `query GetBaselines($profileId: Int, $types: [String]) {
            baselines (profileId: $profileId, types: $types) {
              id
              type
              accelAvg
              accelMax
              strintFilteredStrideRateAvg
              strintFilteredStrideRateMax
              speedAvg
              speedMax
              hrAvg
              hrMax
              energyRatio
              balanceNormalized
              lastSessionDate
              shiftTimeAvg
              activeTime
              calculatedShiftCount
              strideCountLeft
              strideCountRight
            }
          }`,
          variables: {
            profileId,
            types,
          },
        })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
