import React, {useCallback} from 'react';
import {Team} from '../../interfaces';
import {DataGrid, GridColDef, GridRowModel} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import {Paper} from '@mui/material';

interface Props {
  teams: Team[];
}

const TeamsTable: React.FC<Props> = ({teams}) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      filterable: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      resizable: true,
      filterable: true,
    },
    {
      field: 'teamSessionGroupsEnabled',
      headerName: 'Team Session Groups Enabled',
      width: 200,
      resizable: true,
      filterable: true,
    },
    {
      field: 'isPro',
      headerName: 'Pro',
      resizable: true,
      filterable: true,
    },
  ];

  const handleRowClick = useCallback(
    (row: GridRowModel) => {
      navigate(`/admin/teams/${row.id}`);
    },
    [navigate],
  );

  return (
    <Paper sx={{height: 700}}>
      <DataGrid
        rows={teams ?? []}
        columns={columns}
        onRowClick={handleRowClick}
      />
    </Paper>
  );
};

export default React.memo(TeamsTable);
