import {PlayerSession} from '../interfaces';

export const getPlayersTeamGroupSessionTotals = (
  playerSessionsData: PlayerSession[],
) => {
  if (!playerSessionsData.length) {
    return null;
  }
  const teamSessionTotals = {
    startTime: playerSessionsData.reduce((acc, curr) => {
      const currentStartTime = curr.startTime
        ? new Date(curr.startTime)
        : new Date();
      return (acc = currentStartTime < acc ? currentStartTime : acc);
    }, new Date()),
    strideCountLeft: playerSessionsData.reduce((acc, curr) => {
      return acc + (curr.strideCountLeft || 0);
    }, 0),
    strideCountRight: playerSessionsData.reduce((acc, curr) => {
      return acc + (curr.strideCountRight || 0);
    }, 0),
    activeTime: playerSessionsData.reduce((acc, curr) => {
      return acc + (curr.activeTime || 0);
    }, 0),
    strintTotalTime: playerSessionsData.reduce((acc, curr) => {
      return acc + (curr.strintTotalTime || 0);
    }, 0),
    strintFilteredStrideRateAvg: playerSessionsData.reduce((acc, curr) => {
      return acc + (curr.strintFilteredStrideRateAvg || 0);
    }, 0),
    strintFilteredStrideRateMax: playerSessionsData.reduce((acc, curr) => {
      return acc + (curr.strintFilteredStrideRateMax || 0);
    }, 0),
    points: playerSessionsData.reduce((acc, curr) => {
      return acc + (curr.points || 0);
    }, 0),
    hustleScore: parseInt(
      (
        playerSessionsData.reduce((acc, curr) => {
          return acc + (curr.hustleScore || 0);
        }, 0) / playerSessionsData.length || 0
      ).toFixed(),
    ),
    speedAvg:
      playerSessionsData.reduce((acc, curr) => {
        return acc + (curr.speedAvg || 0);
      }, 0) / playerSessionsData.length || 0,
    speedMax: playerSessionsData.reduce((acc, curr) => {
      const currSpeedMax = curr.speedMax || 0;
      return (acc = currSpeedMax > acc ? currSpeedMax : acc);
    }, 0),
    accelAvg:
      playerSessionsData.reduce((acc, curr) => {
        return acc + (curr.accelAvg || 0);
      }, 0) / playerSessionsData.length || 0,
    accelMax: playerSessionsData.reduce((acc, curr) => {
      const currAccelMax = curr.accelMax || 0;
      return (acc = currAccelMax > acc ? currAccelMax : acc);
    }, 0),
    balance:
      playerSessionsData.reduce((acc, curr) => {
        return acc + (curr.balance || 0);
      }, 0) / playerSessionsData.length || 0,
    hrAvg: playerSessionsData.some(ps => ps.hrAvg)
      ? playerSessionsData.reduce((acc, curr) => {
          return acc + (curr.hrAvg || 0);
        }, 0) / playerSessionsData.length || 0
      : null,
    type: playerSessionsData[0].type,
  };
  return teamSessionTotals;
};
