import React from 'react';
import {Box, Typography, LinearProgress} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {ORANGE_COLOR} from '../../styles';

interface Props {
  label?: string;
  labelWidth?: number;
  value: number; // This is a number between 0 and 1
  showValue?: boolean;
  showLock?: boolean;
  overrideText?: string | null;
  overrideColor?: string;
}

const ProgressBar: React.FC<Props> = ({
  label,
  labelWidth,
  value,
  overrideText,
  overrideColor,
  showValue,
  showLock,
}) => {
  // Ensure value is between 0 and 1
  const validValue = Math.min(Math.max(value, 0), 1);

  return (
    <Box display="flex" alignItems="center" my={1}>
      {label && (
        <Box
          sx={{
            marginRight: 2,
            width: labelWidth ? `${labelWidth}px` : 'auto',
          }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 'bold',
              color: '#FFFFFF',
              letterSpacing: 1,
              opacity: 0.85,
            }}>
            {label}
          </Typography>
        </Box>
      )}
      {overrideText ? (
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            letterSpacing: 1,
            opacity: 0.85,
          }}>
          {overrideText}
        </Typography>
      ) : (
        <>
          <Box flexGrow={1} sx={{position: 'relative'}}>
            <LinearProgress
              variant="determinate"
              value={validValue * 100}
              sx={{
                height: 10,
                borderRadius: 6,
                backgroundColor: '#cccccc', // Default to progressBarBgColor
                '& .MuiLinearProgress-bar': {
                  backgroundColor: overrideColor || ORANGE_COLOR, // Default to heliosOrangeNew
                  borderRadius: 6,
                },
              }}
            />
          </Box>
          {showValue && (
            <Box sx={{marginLeft: 2, width: 50}}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                  textTransform: 'uppercase',
                  letterSpacing: 1,
                  opacity: 0.85,
                }}>
                {`${Math.round(validValue * 100)}%`}
              </Typography>
            </Box>
          )}
          {showLock && (
            <Box
              sx={{marginLeft: 2, width: 50, height: 24, textAlign: 'center'}}>
              <LockOutlinedIcon fontSize="small" />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ProgressBar;
