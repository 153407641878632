import React from 'react';
import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ORANGE_COLOR} from '../../../styles';
import {getEnergyRatioFromFloat} from '../../../services/measurementService';

interface Props {
  avgShiftTime?: string;
  // avgTOI?: string;
  // avgShiftCount?: number;
  avgEnergyRatio?: number;
}

const ShiftMetricsRow: React.FC<Props> = ({
  avgShiftTime,
  // avgTOI,
  // avgShiftCount,
  avgEnergyRatio,
}) => {
  const {t} = useTranslation();

  return (
    <Box sx={{marginLeft: 2, marginRight: 2}}>
      <Typography sx={{color: ORANGE_COLOR}} variant="h5">
        {t('statistics.shiftMetrics')}
      </Typography>
      <Box sx={{marginLeft: 5}}>
        <Typography sx={{marginTop: 1}} variant="h5">
          {`${t('statistics.avgShiftTime')} = ${avgShiftTime}`}
        </Typography>
        {/* <Typography sx={{marginTop: 1}} variant="h5">
          {t('statistics.avgTOI')} = {avgTOI}
        </Typography> */}
        {/* <Typography sx={{marginTop: 1}} variant="h5">
          {t('statistics.avgShiftCount')} = {avgShiftCount || 'n/a'}
        </Typography> */}
        <Typography sx={{marginTop: 1}} variant="h5">
          {`${t('statistics.avgEnergyRatio')} = ${getEnergyRatioFromFloat(avgEnergyRatio ?? 0)}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default ShiftMetricsRow;
