import {Baseline, PlayerSession} from '../interfaces';
import {DateTime} from 'luxon';

const isPlayerSession = (
  variable: Baseline | PlayerSession | number,
): variable is PlayerSession => {
  return (variable as PlayerSession).startTime !== undefined;
};

const isAllPlayerSessions = (
  array: (Baseline | PlayerSession)[],
): array is PlayerSession[] => {
  return array.every(isPlayerSession);
};

const getIntensityText = (value?: number) => {
  if (value === 1) {
    return 'Low';
  } else if (value === 2) {
    return 'Avg';
  } else if (value === 3) {
    return 'High';
  }
  return '';
};

const getBaselinesInDateRange = (baselines: Baseline[], daysAgo: number) => {
  const endOfCurrentDay = DateTime.local().endOf('day');
  const dataInRange = baselines.filter(baseline => {
    const relevantDate = baseline.lastSessionDate;
    if (!relevantDate) {
      return false;
    }
    const dateDiff = DateTime.fromISO(relevantDate?.toString()).diff(
      endOfCurrentDay,
      'days',
    ).days;
    return dateDiff * -1 < daysAgo;
  });
  return dataInRange;
};

const getSessionsInDateRange = (
  playerSessions: PlayerSession[],
  daysAgo: number,
) => {
  const endOfCurrentDay = DateTime.local().endOf('day');
  const dataInRange = playerSessions.filter(playerSession => {
    const relevantDate = playerSession.startTime;
    if (!relevantDate) {
      return false;
    }
    const dateDiff = DateTime.fromISO(relevantDate?.toString()).diff(
      endOfCurrentDay,
      'days',
    ).days;
    return dateDiff * -1 < daysAgo;
  });
  return dataInRange;
};

export {
  getIntensityText,
  getSessionsInDateRange,
  isPlayerSession,
  getBaselinesInDateRange,
  isAllPlayerSessions,
};
