import React from 'react';
import {getColor} from '../../services/graphService';
import SessionTileStat from './SessionTileStat';
import {Box, Grid, styled} from '@mui/material';
import {useTranslation} from 'react-i18next';

const UtilizationCell = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface Props {
  utilization: number;
  label: string;
  value: string;
  shrinkText?: boolean;
  overrideColor?: string;
  errorState?: 'pendingUpload' | 'noData';
}

const TeamShiftUtilizationCell: React.FC<Props> = ({
  utilization,
  label,
  value,
  shrinkText,
  overrideColor,
  errorState,
}) => {
  const {t} = useTranslation();

  const cellBackGroundColor = () => {
    if (errorState) {
      return 'transparent';
    }
    return overrideColor || getColor(utilization);
  };
  return (
    <UtilizationCell
      sx={{
        backgroundColor: cellBackGroundColor(),
      }}>
      {!errorState ? (
        <Grid container>
          <Grid item xs={12}>
            <SessionTileStat
              label={label}
              value={value}
              whiteLabel={true}
              size="small"
              shrinkText={shrinkText}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <SessionTileStat
              shrinkText={shrinkText}
              value={t(`general.${errorState}`)}
              whiteLabel={false}
              size="small"
            />
          </Grid>
        </Grid>
      )}
    </UtilizationCell>
  );
};

export default TeamShiftUtilizationCell;
