import {Paper} from '@mui/material';
import {DataGrid, GridColDef, GridRowModel} from '@mui/x-data-grid';
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {Organization} from '../../interfaces';

interface Props {
  organizations: Organization[];
}

const OrganizationsTable: React.FC<Props> = ({organizations}) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      filterable: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      resizable: true,
      filterable: true,
    },
    {
      field: 'country',
      headerName: 'Country',
      resizable: true,
      filterable: true,
    },
    {
      field: 'stateOrProvince',
      headerName: 'State/Province',
      resizable: true,
      filterable: true,
    },
  ];

  const handleRowClick = useCallback(
    (row: GridRowModel) => {
      navigate(`/admin/organizations/${row.id}`);
    },
    [navigate],
  );

  return (
    <Paper sx={{height: 700}}>
      <DataGrid
        rows={organizations ?? []}
        columns={columns}
        onRowClick={handleRowClick}
      />
    </Paper>
  );
};

export default React.memo(OrganizationsTable);
