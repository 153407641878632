import {Paper, Table, TableBody, TableContainer} from '@mui/material';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import PlayerListItem from './PlayerListItem';
import {Player} from '../../interfaces';

interface Props {
  players: Player[];
  teamId: number;
}

const TeamPlayerList: React.FC<Props> = ({players, teamId}) => {
  const {t} = useTranslation();

  const getACWR = useCallback((player: Player) => {
    return [...(player.profile?.acwrScores ?? [])].sort(
      (a, b) =>
        new Date(b.date as string).getTime() -
        new Date(a.date as string).getTime(),
    )?.[0]?.acwrScore;
  }, []);

  const teamHasNumbers = useMemo(
    () => players.some(p => (p.number ?? 0) > 0),
    [players],
  );

  return (
    <TableContainer sx={{mb: 1}} component={Paper}>
      <Table aria-label="simple table" padding="none">
        <TableBody>
          {[...(players ?? [])].map((player: Player) => (
            <PlayerListItem
              key={player.id}
              firstName={player.profile?.firstName || ''}
              lastName={player.profile?.lastName || ''}
              position={player.profile?.position}
              profilePictureUrl={player.profile?.profilePictureFileKey}
              playerACWR={getACWR(player)}
              number={player.number}
              teamHasNumbers={teamHasNumbers}
              playerDashboardLink={{
                text: t('pages.playerDashboard'),
                navigationUrl: `/teams/${teamId}/players/${player.profile?.id}/dashboard`,
              }}
              buttons={[
                {
                  text: t('pages.sessions'),
                  navigationUrl: `/teams/${teamId}/players/${player.profile?.id}/playersessions`,
                },
              ]}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamPlayerList;
