import React from 'react';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '../store/hooks';
import {Helmet} from 'react-helmet';
import {
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import {Roles} from '../enums';
import PageTitle from './shared/PageTitle';
import UnpairTeamDevices from './Admin/UnpairTeamDevices';
import FetchMembershipProDestination from './Admin/FetchMembershipProDestination';
import ResendShopifyMembershipKey from './Admin/ResendShopifyMembershipKey';
import {Link} from 'react-router-dom';
import TocIcon from '@mui/icons-material/Toc';

interface Props {}

const Admin: React.FC<Props> = () => {
  const {t} = useTranslation();
  const role = useAppSelector(state => state.profile.profile?.role);

  return (
    <>
      <Helmet>
        <title>{t('navigation.pageTitle', {pageName: t('pages.admin')})}</title>
      </Helmet>
      <Container>
        {role === Roles.admin && (
          <>
            <PageTitle>{t('pages.admin')}</PageTitle>
            <Paper sx={{mb: 2}}>
              <List>
                <ListItemButton component={Link} to="/admin/organizations">
                  <ListItemIcon>
                    <TocIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('pages.organizations')} />
                </ListItemButton>
                <ListItemButton component={Link} to="/admin/teams">
                  <ListItemIcon>
                    <TocIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('pages.teams')} />
                </ListItemButton>
              </List>
            </Paper>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <UnpairTeamDevices />
              </Grid>
              <Grid item xs={12} md={6}>
                <FetchMembershipProDestination />
              </Grid>
              <Grid item xs={12} md={6}>
                <ResendShopifyMembershipKey />
              </Grid>
            </Grid>
          </>
        )}
        {role !== Roles.admin && (
          <>
            <PageTitle>{t('general.forbidden')}</PageTitle>
          </>
        )}
      </Container>
    </>
  );
};

export default Admin;
