import {Box, Checkbox, Typography} from '@mui/material';
import React from 'react';
import {Control, Controller} from 'react-hook-form';

interface Props {
  control: Control<any>;
  name: string;
  label?: string;
}

const FormCheckbox: React.FC<Props> = ({control, name, label}) => (
  <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <Checkbox
          onChange={e => field.onChange(e.target.checked)}
          checked={field.value}
        />
      )}
    />
    {!!label && (
      <Typography variant="body1" sx={{ml: 2}}>
        {label}
      </Typography>
    )}
  </Box>
);

export default React.memo(FormCheckbox);
