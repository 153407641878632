import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  styled,
} from '@mui/material';
import React, {useState} from 'react';
import {getTime} from '../../services/dateService';
import {
  getSpeedMultiplier,
  getSpeedUnit,
} from '../../services/measurementService';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {useTranslation} from 'react-i18next';
import {updatePlayerSessionShift} from '../../api/playerSessionShiftAPI';
import {reprocessPlayerSession} from '../../api/playerSessionAPI';
import {PlayerSessionShift} from '../../interfaces';
import PlayerSessionShiftItem from './PlayerSessionShiftItem';
import {getPlayerSessionDetailAsync} from '../../store/playerSessionSlice';
import {processSessionVideoAsync} from '../../store/sessionVideoSlice';

const SaveShiftsWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
}));

interface Props {
  playerSessionIds: number[];
  sessionVideoIds: number[];
  shifts: PlayerSessionShift[];
  inReview: boolean;
  setInReview: (inReview: boolean) => void;
  isProcessingVideo: boolean;
}

const PlayerSessionShifts: React.FC<Props> = ({
  playerSessionIds,
  sessionVideoIds,
  shifts,
  inReview,
  setInReview,
  isProcessingVideo,
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const preferredUnits = useAppSelector(
    store => store.profile?.profile?.preferredUnits,
  );

  const [pendingShiftValidUpdates, setPendingShiftValidUpdates] = useState<
    {
      id: number;
      valid: boolean;
    }[]
  >([]);
  const [updatingShifts, setUpdatingShifts] = useState<boolean>(false);

  if (!shifts.length) {
    return (
      <Alert severity="info" variant="outlined">
        There are no shifts for this session
      </Alert>
    );
  }

  const speedMultiplier = getSpeedMultiplier(preferredUnits === 'metric');
  const speedUnit = getSpeedUnit(preferredUnits === 'metric');

  let shiftNumber = 1;
  const shiftsInfo = shifts.map(shift => ({
    id: shift.id,
    valid: shift.valid,
    number: shift.valid ? shiftNumber++ : undefined,
    start: shift.start,
    data: [
      {
        label: 'Time',
        value:
          shift.start && shift.end ? getTime(shift.end - shift.start) : '--',
      },
      {
        label: 'Strides',
        value: shift.strideCountTotal ?? '--',
      },
      {
        label: 'Stride Time',
        value: getTime(shift.strintTotalTime) ?? '--',
      },
      {
        label: 'Stride Speed (Avg)',
        value: shift.speedAvg
          ? (shift.speedAvg * speedMultiplier).toFixed(1)
          : '--',
        unit: speedUnit,
      },
      {
        label: 'Stride Speed (Max)',
        value: shift.speedMax
          ? (shift.speedMax * speedMultiplier).toFixed(1)
          : '--',
        unit: speedUnit,
      },
      {
        label: 'Explosiveness (Avg)',
        value: shift.accelAvg ? (shift.accelAvg / 9.8).toFixed(2) : '--',
        unit: 'g',
      },
      {
        label: 'Explosiveness (Max)',
        value: shift.accelMax ? (shift.accelMax / 9.8).toFixed(2) : '--',
        unit: 'g',
      },
      {
        label: 'Agility (Avg)',
        value: shift.strintFilteredStrideRateAvg?.toFixed(0) ?? '--',
        unit: 'spm',
      },
      {
        label: 'Agility (Max)',
        value: shift.strintFilteredStrideRateMax?.toFixed(0) ?? '--',
        unit: 'spm',
      },
      {
        label: 'Balance (%L / %R)',
        value: shift.balance
          ? `${Math.round(shift.balance * 100)} / ${Math.round((1 - shift.balance) * 100)}`
          : '--',
      },
      {
        label: 'Heart Rate (Avg)',
        value: shift.hrAvg ?? '--',
        unit: 'bpm',
      },
      {
        label: 'Heart Rate (Max)',
        value: shift.hrMax ?? '--',
        unit: 'bpm',
      },
      {
        label: 'Heart Rate (Shift Start)',
        value: shift.hrStart ?? '--',
        unit: 'bpm',
      },
      {
        label: 'Heart Rate (Shift End)',
        value: shift.hrEnd ?? '--',
        unit: 'bpm',
      },
    ],
  }));

  const refresh = () => {
    playerSessionIds.map(id => {
      return dispatch(getPlayerSessionDetailAsync(id));
    });
  };

  return (
    <>
      {inReview && (
        <Alert severity="info" variant="outlined" sx={{mb: 2}}>
          <Typography variant="body1">
            {t('shifts.shiftReviewInstructions')}
          </Typography>
        </Alert>
      )}
      <TableContainer sx={{mb: 2}} component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {shiftsInfo.map(shift => (
              <PlayerSessionShiftItem
                key={shift.start}
                start={shift.start || 0}
                valid={!!shift.valid}
                number={shift.number}
                data={shift.data.map(d => ({
                  label: d.label,
                  value: d.value ? d.value.toString() : '',
                  unit: d.unit,
                }))}
                inReview={inReview}
                onChangeValid={valid => {
                  const existingPendingShiftValidUpdate =
                    pendingShiftValidUpdates.filter(
                      pendingShiftValidUpdate =>
                        pendingShiftValidUpdate.id === shift.id,
                    )[0];
                  // if there is already a pending update for this shift
                  if (existingPendingShiftValidUpdate) {
                    // if the new valid value is the same as the existing shift
                    if (valid === shift.valid) {
                      // remove the pending update
                      setPendingShiftValidUpdates(
                        pendingShiftValidUpdates.filter(
                          pendingShiftValidUpdate =>
                            pendingShiftValidUpdate.id !== shift.id,
                        ),
                      );
                    }
                    // otherwise update the pending update
                    else {
                      setPendingShiftValidUpdates(
                        pendingShiftValidUpdates.map(
                          pendingShiftValidUpdate => {
                            if (pendingShiftValidUpdate.id === shift.id) {
                              return {
                                ...pendingShiftValidUpdate,
                                valid,
                              };
                            }
                            return pendingShiftValidUpdate;
                          },
                        ),
                      );
                    }
                  }
                  // otherwise add a new pending update
                  else {
                    setPendingShiftValidUpdates([
                      ...pendingShiftValidUpdates,
                      {
                        id: shift.id || 0,
                        valid,
                      },
                    ]);
                  }
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {inReview && (
        <>
          {isProcessingVideo && (
            <Alert severity="info" variant="outlined" sx={{mb: 2}}>
              {t('shifts.playerSessionReprocessingError')}
            </Alert>
          )}
          <SaveShiftsWrapper>
            <Button
              variant="contained"
              onClick={async () => {
                setUpdatingShifts(true);
                const shiftUpdatePromises = pendingShiftValidUpdates.map(
                  pendingShiftValidUpdate => {
                    return updatePlayerSessionShift({
                      id: pendingShiftValidUpdate.id,
                      valid: pendingShiftValidUpdate.valid,
                    });
                  },
                );
                await Promise.all(shiftUpdatePromises);
                await Promise.all(
                  playerSessionIds.map(
                    async id => await reprocessPlayerSession({id}),
                  ),
                );
                refresh();
                setUpdatingShifts(false);
                setInReview(false);
              }}
              disabled={
                pendingShiftValidUpdates.length === 0 ||
                updatingShifts ||
                isProcessingVideo
              }>
              {t('general.save')}
              {updatingShifts && (
                <CircularProgress sx={{color: '#fff', ml: 1}} size={16} />
              )}
            </Button>
          </SaveShiftsWrapper>
        </>
      )}
    </>
  );
};

export default PlayerSessionShifts;
