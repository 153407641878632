import {PlayerSession, TeamSession} from '../interfaces';

export const getPlayerSessionGroupTag = (
  playerSession: PlayerSession,
  teamSessions: TeamSession[],
) => {
  if (!playerSession || !teamSessions.length) {
    return '';
  }
  const teamSession = teamSessions.find(
    ts => ts.id === playerSession.teamSessionId,
  );
  if (!teamSession) {
    return '';
  }
  const tags = teamSession.sessionTags;
  return tags?.length ? tags[0].name : '';
};
